import {Risk} from '../types/risk';
import {getStorage} from './localstorageUtil';
import {Appetite, appetiteMap, RiskAppetite} from '../types/riskAppetite';

const getRiskApetite = (risk: Risk): RiskAppetite | object => {
  const filterRiskForApetite = getStorage<RiskAppetite[]>('risk-appetite');
  if (filterRiskForApetite) {
    return filterRiskForApetite.find(
        (apetite) => apetite.impactId === risk.netImpact &&
            (apetite.chance as string) === ((risk.netChance as unknown) as string)) as RiskAppetite;
  } else return {};
};

const colorPossibilityOfOccurrance = (risk: Risk): string => {
  const riskApp = getRiskApetite(risk) as RiskAppetite;
  if (riskApp) {
    const apetite = riskApp.appetite as string;
    switch (apetite) {
      case appetiteMap.get(Appetite.notAccepted):
        return '#DD5858';
      case appetiteMap.get(Appetite.Accepted):
        return '#48BC77';
      case appetiteMap.get(Appetite.Discussable):
        return '#DDBA2A';
      default:
        return '#929292';
    }
  }
  return '#929292';
};

export default colorPossibilityOfOccurrance;
