/* eslint-disable @typescript-eslint/camelcase */
export enum PossibilityOfOccurrance {
  UNLIKELY = 'Menos de 20%',
  POSSIBLE = 'Entre 20 y 50%',
  LIKELY = 'Entre 50 y 80%',
  VERY_LIKELY = 'Mas del 80%',
}

export const possibilityMap = new Map([
  ['UNLIKELY', PossibilityOfOccurrance.UNLIKELY],
  ['POSSIBLE', PossibilityOfOccurrance.POSSIBLE],
  ['LIKELY', PossibilityOfOccurrance.LIKELY],
  ['VERY_LIKELY', PossibilityOfOccurrance.VERY_LIKELY],
]);

const reverseChance = new Map([
  [PossibilityOfOccurrance.UNLIKELY, 'UNLIKELY'],
  [PossibilityOfOccurrance.POSSIBLE, 'POSSIBLE'],
  [PossibilityOfOccurrance.LIKELY, 'LIKELY'],
  [PossibilityOfOccurrance.VERY_LIKELY, 'VERY_LIKELY'],
]);

export const getChance = (key: PossibilityOfOccurrance) => reverseChance.get(key);
export const getChanceLevel = (key: string) => possibilityMap.get(key);
