import {QueryEntity} from '@datorama/akita';
import {controlStore, ControlStore} from '../stores/control.store';
import {ControlState} from '../models/control.model';
import {Control} from '../../types/control';


export class ControlQuery extends QueryEntity<ControlState, Control> {
    public control$ = this.selectAll();
    public activeControl$ = this.selectActive();
    public loading$ = this.selectLoading();
    constructor(protected controlStore: ControlStore) {
      super(controlStore);
    }
}

export const controlQuery = new ControlQuery(controlStore);
