import {RouteComponentProps} from 'react-router';

export const navigateToPath = <T>(url: string, rout: RouteComponentProps, state?: T, replace?: boolean): void => {
  replace ?
    rout.history.replace({pathname: url, state: state}) :
    rout.history.push({
      pathname: url,
      state: state,
    });
};
