import {
  IonAlert, IonCardContent,
  IonDatetime,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonTextarea,
} from '@ionic/react';
import {
  BUTTON_SAVE_TEXT,
  BUTTON_UPDATE_TEXT, COMFIRM_ADD_MESSAGE, COMFIRM_EDIT_MESSAGE,
  DAYS_NAME,
  MONTH_NAME,
  STANDARD_TIME,
  whatever,
} from '../../../../constants';
import {createIonSelectOptionWithEnum} from '../../../dummies/UtilComponents/enumUtil';
import {ReviewFrequency} from '../../../../types/enums/reviewFrequency';
import {ControlTypes} from '../../../../types/controlTypes';
import {TypeControlEnum} from '../../../../types/enums/typeControlEnum';
import {Save} from '../../../dummies/Button-Options/UtilsButton';
import React, {SetStateAction, useState, Dispatch} from 'react';
import {Epp} from '../../../../types/epp';
import {MapControl} from './Register';
import {ID} from '@datorama/akita/lib/types';


interface InputFomr {
    id: ID | boolean;
    description: whatever;
    nextReviewDate: whatever;
    frequency: boolean | string;
    type: boolean | string;
    typeControl: boolean | string;
    epp?: Epp;
}

interface Props {
    dataForm: MapControl;
    setToastConfirm: Dispatch<SetStateAction<any>>;
    setControlForm: Dispatch<SetStateAction<any>>;
    controlForm: InputFomr;
    validForm: InputFomr;
    setEpp: Dispatch<SetStateAction<any>>;
    epp: Partial<Epp>;
    controlTypes: () => whatever;
    setToastAlert: Dispatch<SetStateAction<any>>;
    props: whatever;
    toastAlert: whatever;

}

const RegisterForm: React.FC<Props> = ({
  dataForm,
  setToastConfirm,
  setControlForm,
  controlForm,
  validForm,
  setEpp,
  epp,
  controlTypes,
  setToastAlert,
  props,
}) => {
  const [openDialogEpp, setOpenDialogEpp] = useState(false);
  const {description, nextReviewDate, frequency, type, typeControl} = controlForm;

  const setform = (e: whatever | string): void => {
    dataForm[e.target.name] = e.target.value;
  };

  const showAlertConfirm = (event: whatever): void => {
    event.preventDefault();
    setToastConfirm({
      status: true,
      message: props.history.location.state ? COMFIRM_EDIT_MESSAGE : COMFIRM_ADD_MESSAGE,
    });
  };

  const updateFormControl = (event: whatever): void => {
    setform(event);
    setControlForm({
      ...controlForm,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <IonCardContent className={'ion-content-card'}>
        <form onSubmit={(e): whatever => showAlertConfirm(e)}>
          <IonList lines="full">
            <IonItem className={'ionic-item '} lines={'none'}>
              <IonLabel className={'ionic-label'} position="stacked">Descripción</IonLabel>
              <IonTextarea
                className={'ionic-textArea'}
                name={'description'}
                placeholder="Escriba aquí"
                required
                onIonChange={(e): void => updateFormControl(e)}
                value={description}
              />
            </IonItem>
            <br/>
            <IonItem className={'ionic-item '} lines={'none'}>
              <IonLabel className={'ionic-label'} position="stacked">Siguiente revisión</IonLabel>
              <IonDatetime
                className={'ionic-input ionic-dataTime-padding '}
                cancelText="Cancelar"
                doneText="Aceptar"
                name="nextReviewDate"
                min={new Date().toISOString()}
                max={'2119'}
                value={nextReviewDate}
                placeholder="Seleccione"
                onIonChange={(e): void => updateFormControl(e)}
                displayFormat="DDDD DD de MMMM de YYYY"
                dayNames={DAYS_NAME}
                monthNames={MONTH_NAME}
              />
              {controlForm.nextReviewDate === '' && validForm.nextReviewDate ? (
                                <span style={{color: 'red'}}>Falta siguiente revisión</span>
                            ) : (
                                ''
                            )}
            </IonItem>
            <br/>
            <IonItem className={'ionic-item '} lines={'none'}>
              <IonLabel className={'ionic-label'} position="stacked">Frecuencia de revisón</IonLabel>
              <IonSelect
                className={'ionic-input ionic-dataTime-padding '}
                name="frequency"
                placeholder="Seleccione"
                value={frequency}
                onIonChange={(e): void => updateFormControl(e)}
                cancelText="Cancelar"
              >
                {createIonSelectOptionWithEnum(ReviewFrequency)}
              </IonSelect>
              {controlForm.frequency === '' && validForm.frequency ? (
                                <span style={{color: 'red'}}>Falta frecuencia de revisión</span>
                            ) : (
                                ''
                            )}
            </IonItem>
            <br/>
            <IonItem className={'ionic-item '} lines={'none'}>
              <IonLabel className={'ionic-label'} position="stacked">Categoría</IonLabel>
              <IonSelect className={'ionic-input ionic-dataTime-padding '} name="type"
                placeholder="Seleccione"
                value={type} onIonChange={(e): whatever => updateFormControl(e)}>
                {controlTypes().map((type: ControlTypes, i: number) => (
                  <IonSelectOption key={i} value={JSON.stringify(type)}>
                    {type.title}
                  </IonSelectOption>
                ))}
              </IonSelect>
              {controlForm.type === '' && validForm.type ?
                                <span style={{color: 'red'}}>Falta categoría</span> : ''}
            </IonItem>
            {props.history.location.state ?
                            <>
                              <br/>
                              <IonItem className={'ionic-item '} lines={'none'}>
                                <IonLabel className={'ionic-label'}>Tipo</IonLabel>
                                <IonSelect
                                  className={'ionic-input ionic-dataTime-padding '}
                                  value={typeControl}
                                  onIonChange={(e): whatever => {
                                    setControlForm((prevState: whatever) =>
                                      ({...prevState, typeControl: e.detail.value}));
                                    if (e.detail.value === TypeControlEnum.EPP) {
                                      if (controlForm.epp?.name) setEpp(controlForm.epp);
                                      setOpenDialogEpp(true);
                                    }
                                  }}
                                  placeholder="Seleccione"
                                >
                                  {Object.values(TypeControlEnum).map((value) =>
                                    <IonSelectOption key={value}
                                      value={value}>{value === 'GENERIC' ? 'GENERAL' : 'EPP'}</IonSelectOption>,
                                  )}
                                </IonSelect>
                              </IonItem>
                            </> :
                            null}
          </IonList>
          <Save name={props && props.history.location.state ? BUTTON_UPDATE_TEXT : BUTTON_SAVE_TEXT}/>
        </form>
        <IonAlert
          backdropDismiss={false}
          isOpen={openDialogEpp}
          onDidDismiss={(): void => setOpenDialogEpp(false)}
          header="Registrar Epp"
          inputs={[
            {
              name: 'name',
              type: 'text',
              value: epp.name,
              id: 'name_epp',
              label: 'name',
              placeholder: 'Nombre',
              min: 1,
            },
            {
              name: 'description',
              type: 'text',
              value: epp.description,
              id: 'description_epp',
              label: 'description',
              placeholder: 'Descripción',
            },
          ]}
          buttons={[
            {
              text: 'Ok',
              handler: (e): void => {
                if (e?.name) {
                  setEpp((prevState: whatever) => ({...prevState, ...e}));
                } else {
                  setControlForm((prevState: whatever) => ({
                    ...prevState,
                    typeControl: TypeControlEnum.GENERIC,
                  }));
                  setToastAlert({
                    status: true,
                    message: 'El EPP debe tener un nombre',
                    time: STANDARD_TIME,
                  });
                }
              },
            },
          ]}
        />
      </IonCardContent>
    </>
  );
};

export default RegisterForm;
