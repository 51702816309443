import React, {useState} from 'react';
import {
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList, IonText,
  IonTextarea,
  IonToggle,
} from '@ionic/react';
import {Save} from '../../../../../../dummies/Button-Options/UtilsButton';
import './register-employee-form.scss';
import {AppToolbar} from '../../../../../../dummies/UtilComponents/toolbar/appToolbar';
import {REGISTER_EMPLOYEE_FORM} from '../../../../../../../constants';
import {useEmployee} from '../../../../application/hooks/employee.hooks';
import {RouteComponentProps} from 'react-router';

const RegisterEmployeeForm: React.FC<RouteComponentProps> = ({history}) => {
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [invitation, setInvitation] = useState(false);
  const [{createEmployee}] = useEmployee();

  const resetForm = (): void => {
    setUserName(undefined);
    setEmail(undefined);
    setPhoneNumber(undefined);
    setInvitation(false);
  };

  const handleFormSubmit = (event: whatever): void => {
    event.preventDefault();
    createEmployee({
      deviceTokens: [],
      email, userName: userName as unknown as string, phoneNumber: phoneNumber as unknown as string,
      guide: true,
    }, invitation);
    resetForm();
    setTimeout(() => history.push('/tab22'), 5000);
  };

  return (<>
    <IonContent>
      <IonHeader className="headerTitle">
        <AppToolbar title={REGISTER_EMPLOYEE_FORM} />
      </IonHeader>
      <IonCardContent className={'card-register-employee'}>
        <form onSubmit={handleFormSubmit}>
          <IonList lines={'full'}>
            <IonItem lines={'none'}>
              <IonLabel className={'form-label'} position={'stacked'}>Nombre</IonLabel>
              <IonTextarea className={'form-text-area'} placeholder={'Escriba el nombre'} required
                onIonChange={(event: whatever): void => setUserName(event.target.value)}/>
            </IonItem>
            <IonItem lines={'none'}>
              <IonLabel className={'form-label'} position={'stacked'}>Correo</IonLabel>
              <IonTextarea className={'form-text-area'} placeholder={'Escriba el correo'} required
                onIonChange={(event: whatever): void => setEmail(event.target.value)}/>
            </IonItem>
            <IonItem lines={'none'}>
              <IonLabel className={'form-label'} position={'stacked'}>Teléfono</IonLabel>
              {/* eslint-disable-next-line max-len */}
              <IonInput className={'form-text-area'} placeholder={'Escriba el numero de teléfono'} type={'number'} required
                onIonChange={(event: whatever): void => setPhoneNumber(event.target.value)}/>
            </IonItem>
            <IonItem><IonToggle color="primary" checked={invitation}
              onIonChange={(event: whatever): void => setInvitation(event.detail.checked)}/>
            <IonText>
              <h4>Enviar correo de acceso a la aplicación</h4>
            </IonText>
            </IonItem>
          </IonList>
          <Save name={'Guardar'}/>
        </form>
      </IonCardContent>
    </IonContent>
  </>);
};
export default RegisterEmployeeForm;
