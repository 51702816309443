/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {SetStateAction, useEffect, useMemo, useState} from 'react';
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonLoading, IonGrid, IonRow, IonCol,
} from '@ionic/react';
import './RiskRegister.scss';
import {Risk} from '../../../types/risk';
import Fuse from 'fuse.js';
import {RouteComponentProps, withRouter} from 'react-router';
import {BUTTON_ANIMATION_CLASS, RISK_LIST, whatever} from '../../../constants';
import {RefresherEventDetail} from '@ionic/core';
import {add, checkmarkCircleOutline} from 'ionicons/icons';
import {AppToolbar} from '../../dummies/UtilComponents/toolbar/appToolbar';
import EmptyDataIcon from '../../dummies/UtilComponents/InfoEmptyDataIcon';
import {RiskCategory} from '../../../types/enums/riskCategory';
import CategoryFilter from '../../dummies/CategoryFilter/Filter';
import {CreateRiskList} from '../Control/CreateRiskList';
import {useRisk} from '../../../akita/hooks/risk.hooks';

interface Prop extends RouteComponentProps {
    riskList?: Risk[];
}
const RiskRegister: React.FC<Prop> = (props)=> {
  const [{getRisks: akitaGetRisk, risks, removeRisk}] = useRisk();
  const [sString, setSstring] = useState('');
  const [selCategories, setSelCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [onLoadingRisk, setOnLoadingRisk] = useState<boolean>(false);
  const [clAnimation, setClAnimation] = useState<string>('');
  const [displayRiskList, setDisplayRiskList] = useState<Risk[]>([]);
  const [onOpen] = useState<boolean>(true);

  const options = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['description'],
  };

  const handleOnChange = (event: whatever): void => {
    setSstring(event.target.value);
  };

  const handleCategoryChange = (cat: SetStateAction<never[]> ): void => {
    setSelCategories(cat);
    if (!categories.length) setCategories(cat);
  };

  useEffect(()=>{
    akitaGetRisk();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setOnLoadingRisk(true);

    if (!risks.length && !onLoadingRisk) {
      setClAnimation(BUTTON_ANIMATION_CLASS);
    }
    if (risks.length && !selCategories.length) {
      setDisplayRiskList(risks);
    }

    if (!risks.length) {
      setDisplayRiskList([]);
      setOnLoadingRisk(false);
    }
    // eslint-disable-next-line
  }, [risks]);

  useEffect(() => {
    if (props.history.location.state ) {
      const aux = [];
      aux.push(props.history.location.state);
      // setSelCategories(selCategories[(props.history.location.state as whatever).categories.id]);
      setSelCategories(aux as whatever);
      // setState((state)=>( {...state, selectedCategories: [(props.history.location.state as whatever).category.id]}));
    }
    // akitaGetRisk();
    // eslint-disable-next-line
  }, []);

  const reload = (event: CustomEvent<RefresherEventDetail>): void => {
    akitaGetRisk();
    event.detail.complete();
  };

  useEffect(() => {
    if (risks.length > 0) {
      const filteredRisk = risks.filter((value: Risk) =>
        selCategories.some((value1: whatever) => {
          return value1.id === value.category;
        }));
      let result: whatever = selCategories.length ? filteredRisk : displayRiskList;
      const fuse = new Fuse(result, options);
      result = sString ? fuse.search(sString) : result;
      setDisplayRiskList(result);
    }
    // eslint-disable-next-line
  }, [selCategories, sString]);

  useEffect(() => {
    if (!sString) {
      setDisplayRiskList(risks);
    }
    // eslint-disable-next-line
  }, [sString]);

  useEffect(() => {
    if (!selCategories.length && risks) {
      setDisplayRiskList(risks);
    }
    // eslint-disable-next-line
  }, [selCategories]);

  const categoriesFilter =(): RiskCategory[] =>{
    return risks.map((x: Risk) => x.category as RiskCategory)
        .filter(function(item: number, pos: number, a: number[]): boolean {
          return a.indexOf(item) === pos;
        });
  };

  return (
    <>
      <IonHeader className="headerTitle">
        <AppToolbar title={RISK_LIST} />
      </IonHeader>

      {risks.length > 0 ? (
                <>
                  <div style={{display: 'flex', alignItems: 'center'}} className={'divSearchFilter'}>
                    <CategoryFilter
                      categories={categoriesFilter()}
                      changeCategory={handleCategoryChange}
                      selected={selCategories}
                    />
                    {/* eslint-disable-next-line max-len */}
                    <IonSearchbar className="search-bar" placeholder='Buscar' value={sString} onIonChange={(e): void => handleOnChange(e)} />
                  </div>
                  <IonContent style={{height: '80%'}}>
                    <IonRefresher slot={'fixed'} closeDuration={'500'} onIonRefresh={reload}>
                      <IonRefresherContent pullingIcon="arrow-dropdown"
                        pullingText="Refrescar" refreshingSpinner="circles" />
                    </IonRefresher>
                    <IonGrid className="my-grid-content">
                      <IonRow>
                        <IonCol>
                          {onOpen ? (<CreateRiskList RiskList={displayRiskList} checkDelete={removeRisk}/>) : null}
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonContent>
                </>
            ) : !onLoadingRisk ? (<EmptyDataIcon icon={checkmarkCircleOutline} message="riesgos" />
            ) : null}
      <IonFab vertical="bottom" horizontal="end" className={clAnimation}>
        <IonFabButton className="fab-btn" onClick={(): void => props.history.push('/tab7')}>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
      <IonLoading cssClass="spiner" isOpen={onLoadingRisk} message={'Cargando . . .'} duration={1000} />
    </>
  );
};

export default withRouter(RiskRegister);
