import React, {useEffect, useState} from 'react';
import {IonCol, IonGrid, IonIcon, IonRow} from '@ionic/react';
import './LoginLanding.scss';
import {whatever} from '../../../../constants';
import {setToken} from '../../../../helpers/service';
import {Plugins} from '@capacitor/core';
import {arrowUndo} from 'ionicons/icons';
import {getProfile} from '../../../../services/userAuthorization.service';

const {SplashScreen} = Plugins;

// eslint-disable-next-line @typescript-eslint/class-name-casing
export interface props {
    mode: 'register' | 'access';
    onBack: () => void;
    email?: string;
}

const receiveMessage = (event: whatever): void => {
  const {token} = event.data;
  if (token) {
    setToken(token);
    getProfile(true, token?.map?.token ?? token.token).then((value) => {
      if (value.status === 200) {
        window.location.href = '#/tab18';
        window.location.reload();
      }
    });
  }
};

const LoginLanding: React.FC<props> = ({onBack, mode, email}) => {
  const [show, setShow] = useState(true);


  useEffect(() => window.addEventListener('message', receiveMessage, false), []);

  useEffect(() => {
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 100);
  }, []);


  return (
    <>
      <IonGrid>
        <IonIcon style={{cursor: 'pointer', fontSize: '3rem', color: 'white'}}
          onClick={(): void => onBack()} icon={arrowUndo}/>
        <IonRow>
          <IonCol sizeXs="1" sizeLg="3" sizeMd="3" sizeXl="4"/>
          <IonCol sizeXs="10" sizeLg="6" sizeMd="6" sizeXl="4">
            {show ?
                            <iframe
                              className="iframe-style"
                              onLoad={(): whatever => SplashScreen.hide()}
                              onError={(): whatever => SplashScreen.hide()}
                              style={{width: '100%', height: '100%', border: 'none', display: 'initial', zIndex: 99}}
                              src={`${process.env.REACT_APP_ACCOUNTS_APP_URL}?appName=${'SIBLA'}&mode=${mode}${email ?
                                  '&email=' + email : ''}`}
                              title="accounts"
                              name="ifr_login"
                              allow="fullscreen https://apps.beamar.co"
                            /> : null}
          </IonCol>
          <IonCol sizeXs="1" sizeLg="3" sizeMd="3" sizeXl="4"/>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default LoginLanding;
