import React, {useState} from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonInput, IonButton, IonText, IonImg,
} from '@ionic/react';
import './LadingStyles.scss';
import LoginLanding from './LoginLanding/LoginLanding';
import {whatever} from '../../../constants';

const Landing: React.FC = () => {
  const [onLogin, setOnLogin] = useState(false);
  const [mode, setMode] = useState<'access' | 'register'>('access');
  const [email, setEmail] = useState<string>();

  return (
    <div className="lorenzo1">
      { !onLogin ?
            <IonGrid class="lorenzo">
              <IonRow className="top-row"/>
              <IonRow>
                <IonCol sizeXl="2" sizeLg="1" sizeMd="1" sizeSm="12" sizeXs="12"></IonCol>
                <IonCol sizeXl="5" sizeLg="4" sizeMd="12" sizeSm="12" sizeXs="12">
                  <div className="smart-show">
                    <IonGrid className="grid-title">
                      <IonText>
                        Tu <b>sistema de bienestar laboral</b>  a la mano.
                      </IonText>
                    </IonGrid>
                  </div>
                  <div className="smart-hide landing-text">
                    <IonGrid className="grid-title">
                      <IonText>
                        <b>
                          Tu sistema de bienestar laboral a la mano.
                        </b>
                      </IonText>
                    </IonGrid>
                    <IonGrid className="grid-subtitle">
                      <IonText>
                        Elabora <b>tu plan de trabajo</b>, haz seguimimiento a
                        los <b>riesgos y peligros</b> de tu empresa, cumple
                        con el ciclo <b>PHVA</b>, recibe  <b> actualizaciones en
                        tiempo real y más.</b>
                      </IonText>
                    </IonGrid>
                  </div>
                </IonCol>
                <IonCol sizeXl="4" sizeLg="5" sizeMd="12" sizeSm="12" sizeXs="12">
                  <IonCard className="main-card">
                    <IonCardContent className="card-content">
                      <IonGrid className="grid-image">
                        <IonImg className="imagen-card"
                          src={process.env.PUBLIC_URL + '/assets/images/logo-sibla-version-web.png'} />
                      </IonGrid>
                      <IonGrid className="texto-card-1">
                        <IonText>
                          Diseña el <strong>SG-SST</strong> de tu empresa <strong>gratis.</strong>
                        </IonText>
                      </IonGrid>
                      <IonGrid className="register-text">
                        <IonText>
                          <b>Registrate ahora.</b>
                        </IonText>
                      </IonGrid>
                      <IonGrid>
                        <IonInput type="email" placeholder="E-mail" className="card-input"
                          onIonChange={(e: whatever): void => setEmail(e.target.value)}/>
                        <IonGrid>
                          <IonRow>
                            <IonCol size-lg="6" size-md="6" size-sm="6">
                              <IonButton className="card-button" onClick={(): void => {
                                setMode('register');
                                setOnLogin(true);
                              }}>
                                Registrarse
                              </IonButton>
                            </IonCol>
                            <IonCol size-lg="6" size-md="6" size-sm="6">
                              <IonButton className="card-button-login" onClick={(): void => setOnLogin(true)}>
                                Ingresar
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
                <IonCol sizeXl="1" sizeLg="1" sizeMd="4" sizeSm="12" sizeXs="12">
                </IonCol>
              </IonRow>
            </IonGrid> :
            <LoginLanding email={email} mode={mode} onBack={(): void => {
              setMode('access');
              setEmail(undefined);
              setOnLogin(false);
            }} />
      }
      {/* </IonContent>*/}
    </div>
  );
};
export default Landing;
