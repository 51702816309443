import {Observable, Subscription} from 'rxjs';
import {Company} from '../../../Domain/interface/company/company';
import {companyQuery, CompanyQuery} from '../../../Domain/entities/company/query/company.query';
import {companyService, CompanyService} from '../service/company/company.service';
import {ID} from '@datorama/akita/lib/types';
import {Media} from '../../../types/media';


export class CompanyFacade {
    public readonly company$ = this.companyQuery.company$;
    public readonly activeCompany$ = this.companyQuery.activeCompany$ as Observable<Company>;
    public readonly loading$ = this.companyQuery.loading$;
    private readonly subscriptions = new Subscription();

    constructor(private companyQuery: CompanyQuery, private companyService: CompanyService) {}


    public getCompany(): void {
      this.subscriptions.add(this.companyService.listAll().subscribe());
    }

    public saveCompany(id: ID, infoCompany: Company ): void {
      this.subscriptions.add(this.companyService.updateCompany(id, infoCompany).subscribe());
    }
    public saveEvidenceCompany(companyId: ID, Evidences: Media[]): void {
      this.subscriptions.add(this.companyService.saveEvidenceCompany(companyId, Evidences).subscribe());
    }
    public getDocumentCompany(documentId: ID, detail?: boolean): void {
      // eslint-disable-next-line no-console
      console.log('DETAILED : ', detail);
      this.subscriptions.add(this.companyService.getDocumentCompany(documentId, detail).subscribe());
    }
    public removeDocumentCompany(id: ID, documentId: number): void {
      this.subscriptions.add(this.companyService.removeDocumentCompany(id, documentId).subscribe());
    }
}

export const companyFacade = new CompanyFacade(companyQuery, companyService);
