import React, {Suspense} from 'react';
// COMPONENTS IONIC
import {IonApp, IonLoading, IonPage, IonRouterOutlet, IonSplitPane} from '@ionic/react';
// LIBRARIES
import {HashRouter as Router, Route, Redirect} from 'react-router-dom';
// CONSTANTS AND TYPES
import {RISK_URL_INHERENT_IMPACT} from '../constants';
// UTILITIES
import {getStorage} from '../helpers/localstorageUtil';
import LoginValidation from './dummies/LoginValidation';
// CSS
import '../globals.scss';
import '../theme/variables.scss';
import {
  RiskToast,
  RegisterControl,
  RiskRegister,
  AppetiteManager,
  RiskDetail,
  RiskControl,
  Menu,
  WorkPlan,
  Logout,
  DirectorDashboard,
  InsecureAct,
  UserAccount,
  InsecureActList,
  RefreshToken,
  MinimalStandardDetail,
  CompanyProfile,
  SlideTutorials,
  LoadApp,
  EvidencePage,
  MinimalStandardContainer,
  ControlDetails, Users, RegisterEmployeeForm,
} from './indexImport';

// CONTEXT

export const notificationContext = React.createContext({notifications: ''});

const App: React.FC = () => {
  return (
    <LoginValidation>
      <Router basename="">
        <Suspense fallback={ <IonLoading isOpen={true} message={'Cargando...'} duration={4000} />}>
          <div className="App">
            <IonApp>
              <IonSplitPane contentId="main" when="xl">
                <Menu />
                <IonPage id="main">
                  <IonRouterOutlet>
                    <Route exact path="/" render={(): JSX.Element => <Redirect to="/tab18" />} />
                    <Route path="/:tab(tab1)" component={DirectorDashboard} />
                    <Route path="/:tab(tab3)" render={(): JSX.Element => <RiskRegister />} />
                    <Route path="/:tab(tab4)" render={(): JSX.Element => <RiskControl/>} />
                    <Route path="/:tab(tab5)" component={WorkPlan} />
                    <Route path="/:tab(tab6)" component={Logout} />
                    <Route path="/:tab(tab7)" component={RiskToast} />
                    <Route path="/:tab(tab8)" component={RegisterControl} />
                    <Route path="/:tab(tab9)" component={RiskDetail} />
                    <Route path="/:tab(tab10)" render={(): JSX.Element => <ControlDetails/>}/>
                    <Route path="/:tab(tab11)" render={(): JSX.Element => <AppetiteManager
                      impacts={getStorage(RISK_URL_INHERENT_IMPACT)} />}/>
                    <Route path="/:tab(tab12)" component={InsecureAct} />
                    <Route path="/:tab(tab13)" component={InsecureActList} />
                    <Route path="/:tab(tab14)" component={UserAccount} />
                    <Route path="/:tab(tab15)" render={(): JSX.Element => <RefreshToken />} />
                    <Route path="/:tab(tab16)" render={(): JSX.Element => <CompanyProfile />} />
                    <Route path="/:tab(tab17)" render={(): JSX.Element => <MinimalStandardContainer/>} />
                    <Route path="/:tab(tab18)" render={(): JSX.Element => <LoadApp />} />
                    <Route path="/:tab(tab19)" render={(): JSX.Element => <EvidencePage/>}/>
                    <Route path="/:tab(tab20)/:id" render={(): JSX.Element => <MinimalStandardDetail />}/>
                    <Route path="/:tab(tab22)" render={(): JSX.Element => <Users />}/>
                    <Route path="/:tab(tab23)" component={RegisterEmployeeForm}/>
                  </IonRouterOutlet>
                </IonPage>
              </IonSplitPane>
            </IonApp>
          </div>
          <Route path="/:tab(tab21)" render={(): JSX.Element => <SlideTutorials />} />
        </Suspense>
      </Router>
    </LoginValidation>
  );
};

export default App;
