/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-function-return-type */
import {PossibilityOfOccurrance} from './enums/possibilityOfOccurrance';
import {ID} from '@datorama/akita/lib/types';
export interface RiskAppetite {
  id: ID;
  impactId: number;
  impactOrder: number;
  chance: PossibilityOfOccurrance;
  appetite: Appetite;
}

export enum Appetite {
  notAccepted = '#DD5858',
  Discussable = '#DDBA2A',
  Accepted = '#48BC77',
  Initial = 'white',
}

const colorOrderMap = new Map([
  [Appetite.Initial, Appetite.notAccepted],
  [Appetite.notAccepted, Appetite.Discussable],
  [Appetite.Discussable, Appetite.Accepted],
  [Appetite.Accepted, Appetite.notAccepted],
]);

export const appetiteMap = new Map([
  [Appetite.notAccepted, 'NOT_ACCEPTED'],
  [Appetite.Discussable, 'DISCUSSABLE'],
  [Appetite.Accepted, 'ACCEPTED'],
  [Appetite.Initial, 'INITIAL'],
]);

const reverseAppetiteMap = new Map([
  ['NOT_ACCEPTED', Appetite.notAccepted],
  ['DISCUSSABLE', Appetite.Discussable],
  ['ACCEPTED', Appetite.Accepted],
  ['INITIAL', Appetite.Initial],
]);

export const getAppetite = (color: Appetite) => appetiteMap.get(color);
export const getAppetiteColor = (color: string) => reverseAppetiteMap.get(color);
export const nextColor = (color: Appetite) => colorOrderMap.get(color);
