/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useEffect, useState} from 'react';
import {IonButton} from '@ionic/react';
import './AppetiteBox.scss';
import {nextColor, RiskAppetite} from '../../../../../types/riskAppetite';
import {whatever} from '../../../../../constants';

interface Prop {
    riskAppetite: RiskAppetite;
    updateColor: whatever;
}

const AppetiteBox: React.FC<Prop> = ({riskAppetite, updateColor}) => {
  const [currentColor, setCurrentColor] = useState(riskAppetite.appetite);
  const [backgroundColor, setBackgroundColor] = useState<whatever>({backgroundColor: riskAppetite.appetite});

  // console.log('risk Appetite appetiteBox ', riskAppetite);

  const changeBackgroundColor = (backgroundColor: string): void => {
    setBackgroundColor( {backgroundColor: backgroundColor});
  };
  useEffect(()=>{
    setCurrentColor(riskAppetite.appetite);
    setBackgroundColor({backgroundColor: riskAppetite.appetite});
    // eslint-disable-next-line
  }, [riskAppetite.appetite]);

  const updateAppetite = (): whatever => {
    const color = nextColor(currentColor)!;
    riskAppetite.appetite = color;
    setCurrentColor(color);
    changeBackgroundColor(color);
    return riskAppetite;
  };

  return (
    <IonButton
      style={backgroundColor}
      color="white"
      onClick={(): void => updateColor(updateAppetite())}
      className="appetite-box"
    />
  );
};
export default AppetiteBox;
