import {PossibleEffects} from '../../../types/possibleEffect';
import {whatever} from '../../../constants';
import React, {useState} from 'react';
import {IonAlert, IonInput, IonLabel} from '@ionic/react';

interface PropsMultiselect {
    item: PossibleEffects[];
    labelInput: string;
    title: string;
    selected: string[];
    changeValue: whatever;
}

const labelMultiselect = (arraySelect: PossibleEffects[]): string => {
  return arraySelect
      .map((x) => {
        return x ? x.title : '';
      })
      .splice(0, 3)
      .join(', ')
      .concat(arraySelect.length > 3 ? `... Mas ${arraySelect.length - 3}` : '');
};
const PosibleEffectMultiselect: React.FunctionComponent<PropsMultiselect> = (prop) => {
  const [isOpen, setOpen] = useState(false);
  const selectFilter: PossibleEffects[] = [];
  if (Array.isArray(prop.selected) && prop.selected.every((it) => typeof it === 'string')) {
    prop.selected.map((x) => {
      selectFilter.push(prop.item.find((y) => y.id === x) as PossibleEffects);
      return x;
    });
  }
  return (
    <>
      <IonLabel className={'ionic-label'} position="stacked">{prop.labelInput}</IonLabel>
      <IonInput
        className={'ionic-input '}
        required
        placeholder="Seleccione"
        readonly
        onClick={(): void => {
          setOpen(true);
        }}
        value={labelMultiselect(selectFilter)}
      />
      <IonAlert
        isOpen={isOpen}
        onDidDismiss={(): void => setOpen(false)}
        header={prop.title}
        inputs={
                    prop.item.map((item) => ({
                      name: item.title,
                      type: 'checkbox',
                      label: typeof item === 'object' ? item.title : item,
                      value: item,
                      checked: selectFilter.some((y) => item === y),
                    })) as whatever
        }
        buttons={[
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Aceptar',
            handler: (x): whatever => prop.changeValue(x),
          },
        ]}
      />
    </>
  );
};


export default PosibleEffectMultiselect;
