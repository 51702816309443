import React, {useState} from 'react';
import {
  IonHeader,
  IonToast,
} from '@ionic/react';
import {ReviewFrequency} from '../../../../types/enums/reviewFrequency';

import {Control} from '../../../../types/control';
import {
  CONTROL_URL_TYPES,
  EDIT_CONTROL,
  MINUTE,
  REGISTER_CONTROL,
  STANDARD_TIME,
  SUCCESS_DATA_SAVED,
  whatever,
} from '../../../../constants';

import {getStorage} from '../../../../helpers/localstorageUtil';
import {ControlTypes} from '../../../../types/controlTypes';
import {RouteComponentProps, withRouter} from 'react-router';
import moment from 'moment';
import {navigateToPath} from '../../../../helpers/navigationUtil';
import {AppToolbar} from '../../../dummies/UtilComponents/toolbar/appToolbar';
import {Epp} from '../../../../types/epp';
import {useControl} from '../../../../akita/hooks/control.hooks';
import RegisterForm from './RegisterForm';
import {ID} from '@datorama/akita/lib/types';

interface Prop extends RouteComponentProps {
  ctrl?: Control;
}
export interface MapControl {
  [key: string]: string | number;
}
export const loadFrencuency = (value: string): string => {
  const keys = Object.keys(ReviewFrequency);
  for (const Frequency in keys) {
    if (keys[Frequency] === value) return keys[Frequency] as string;
  }
  return '';
};

const RegisterControl: React.FC<Prop> = (props: whatever) => {
  const [controlForm, setControlForm] = useState<whatever>({
    id: 0,
    description: '',
    nextReviewDate: '',
    frequency: '',
    type: '',
    typeControl: '',
  });
  const [validForm, setValidForm] = useState({
    id: false,
    description: false,
    nextReviewDate: false,
    frequency: false,
    type: false,
    typeControl: false,
  });
  const [epp, setEpp] = useState<Partial<Epp>>({});
  const [toastAlert, setToastAlert] = useState({status: false, message: '', time: STANDARD_TIME});
  const [toastConfirm, setToastConfirm] = useState({status: false, message: ''});
  let dataForm: MapControl = {id: 0, description: '', nextReviewDate: '', frequency: '', type: '', typeControl: ''};
  const [{saveControl: saveControlAkita, updateControl: updateControlAkita}] = useControl();

  const controlTypes = (): whatever => {
    return getStorage<ControlTypes[]>(CONTROL_URL_TYPES);
  };

  const resetForm = (): void => {
    dataForm = {id: 0, description: '', nextReviewDate: '', frequency: '', type: '', typeControl: ''};
    setControlForm(dataForm);
  };


  const findType = (id: ID): string => {
    let aux: ControlTypes = {id: 0, title: ''};
    controlTypes().forEach((element: ControlTypes) => {
      if (element.id === id) aux = element;
    });
    return JSON.stringify(aux);
  };

  if ( !controlForm.id &&
      props.history.location.state &&
      !props.history.location.state.dataDetail ) {
    const aux: Control = props.history.location.state;
    setControlForm({
      id: aux.id as ID,
      description: aux.description,
      nextReviewDate: aux.nextReviewDate,
      frequency: loadFrencuency(props.history.location.state.frequency),
      type: findType(aux.type as ID),
      typeControl: aux.typeControl,
      epp: aux.epp,
    });
  }


  const CheckFields = (formData: Control): boolean => {
    let response = false;
    const values = Object.values(formData);
    const keys = Object.keys(formData);

    for (let i = 0; i < values.length; i++) {
      if (values[i] && keys[i] !== 'id' && !values[i].trim()) {
        response = true;
        setValidForm({...validForm, [keys[i]]: true});
      }
    }
    return response;
  };

  const parseToSave = (formData: whatever): Control => {
    const aux: Control = {
      description: formData.description,
      frequency: formData.frequency,
      nextReviewDate: `${moment(new Date(formData.nextReviewDate)).format('YYYY-MM-DDTHH:mm:ss')}Z`,
      type: formData.type !== '' ? JSON.parse(formData.type).id : '',
      typeControl: formData.typeControl,
      epp: epp.name ? epp : formData.epp ?? undefined,
    };
    if (formData.id) aux.id = formData.id;
    // if (formData.typeControl === 'epp') aux.epp = formData.epp;
    return aux;
  };


  const toastMessage = ( message: string): void => {
    setToastAlert({status: true, message: message, time: STANDARD_TIME});
  };

  const saveControl = (data: whatever): void =>{
    saveControlAkita(parseToSave(data));
    toastMessage(SUCCESS_DATA_SAVED);
    resetForm();
    navigateToPath('tab4', props, null, true);
  };

  const updateControlF = (data: whatever): void => {
    updateControlAkita(parseToSave(data));
    props.history.replace({pathname: 'tab10', state: parseToSave(data)});
  };

  const save = (data: Control): void => {
    if (data.typeControl === '') {
      data.typeControl = 'GENERIC';
    }

    if (!CheckFields(data)) {
      if (!props.history.location.state) return saveControl(data);
      updateControlF(data);
    }
  };


  return (
    <>
      <IonToast
        isOpen={toastAlert.status}
        color={'dark'}
        onDidDismiss={(): whatever =>
          setToastAlert({status: false, message: toastAlert.message, time: toastAlert.time})}
        message={toastAlert.message}
        position={'bottom'}
        duration={toastAlert.time}
        animated={true}
      />
      <IonToast
        isOpen={toastConfirm.status}
        onDidDismiss={(): whatever => setToastConfirm({status: false, message: toastConfirm.message})}
        message={toastConfirm.message}
        color={'dark'}
        position={'bottom'}
        duration={MINUTE}
        animated={true}
        buttons={[
          {
            side: 'end',
            text: 'Aceptar',
            handler: (): void => {
              save(controlForm);
            },
          },
          {
            side: 'end',
            text: 'Cerrar',
            handler: (): whatever => setToastConfirm({...toastConfirm, status: false}),
          },
        ]}

      />
      <IonHeader>
        {props.history.location.state ? (
          <AppToolbar
            title={EDIT_CONTROL}
            navigation={{props, defHref: '/tab4', url: 'tab10', data: parseToSave(controlForm)}}
          />
        ) : (
          <AppToolbar title={REGISTER_CONTROL} />
        )}
      </IonHeader>
      <RegisterForm dataForm={dataForm}
        setToastConfirm={setToastConfirm}
        setControlForm={setControlForm}
        controlForm={controlForm}
        validForm={validForm}
        setEpp={setEpp}
        epp={epp}
        toastAlert={toastAlert}
        controlTypes={controlTypes}
        setToastAlert={setToastAlert}
        props={props}
      />
    </>
  );
};
export default withRouter(RegisterControl);
