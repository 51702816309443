/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useEffect, useState} from 'react';
import AppetiteBoard from '../AppetiBoard/AppetiteBoard';
import './AppetiteManager.scss';
import {RiskAppetite, getAppetite} from '../../../../../types/riskAppetite';
import {IonHeader, IonGrid, IonRow, IonCol, IonButton, IonContent, IonLoading} from '@ionic/react';
import {Impact} from '../../../../../types/impact';
import {getChance} from '../../../../../types/enums/possibilityOfOccurrance';
import {ImpactDescription} from '../ImpactDescription/ImpactDescription';
import {ANIMATION_FADE_IN_DOWN, APPETITE_TITLE, whatever} from '../../../../../constants';
import {AppToolbar} from '../../../../dummies/UtilComponents/toolbar/appToolbar';
import {useRisk} from '../../../../../akita/hooks/risk.hooks';

interface Prop {
    impacts: Impact[];
}

const AppetiteManager: React.FC<Prop> = ({impacts}) => {
  const update1: RiskAppetite[] = [];
  const [riskAppetites, setRiskAppetites] = useState<RiskAppetite[]>([]);
  const [toUpdate, setToUpdate] = useState<whatever>(update1);
  const [toast, setToast] = useState<whatever>(false);
  const [loading, setloading] = useState<whatever>(true);
  const [{getRiskAppetites, risks, saveRiskAppetites}] = useRisk();

  useEffect(() => {
    if (loading) {
      getRiskAppetites();
      setloading(false);
    }
    // eslint-disable-next-line
  },[]);
  useEffect(() => {
    setRiskAppetites( risks );

    // eslint-disable-next-line
  }, [risks]);

  const transFormData = (riskAppetite: RiskAppetite): whatever => {
    return {
      id: riskAppetite.id,
      impactId: riskAppetite.impactId,
      impactOrder: riskAppetite.impactOrder,
      chance: getChance(riskAppetite.chance),
      appetite: getAppetite(riskAppetite.appetite),
    };
  };

  const updateRiskAppetite = (): whatever => {
    const existingAppetites = toUpdate.map((riskAppetite: RiskAppetite) => transFormData(riskAppetite));
    const updateAppetite = existingAppetites.concat(riskAppetites);
    setRiskAppetites(updateAppetite);
    saveRiskAppetites(existingAppetites);
    setToast(true);
  };

  const revertUnsavedChanges = async (): Promise<whatever> => {
    setToUpdate([]);
    getRiskAppetites(true);
    setloading(true);
  };

  const updateColor = (appetite: RiskAppetite): whatever => {
    // eslint-disable-next-line no-unused-expressions
    const element = toUpdate.filter((riskAppetite: RiskAppetite) => {
      return riskAppetite.chance === appetite.chance && riskAppetite.impactOrder === appetite.impactOrder;
    })[0];
    const index = toUpdate.indexOf(element!);
    if (index !== -1) {
      toUpdate.splice(index, 1, appetite);
    } else {
      toUpdate.push(appetite);
    }
  };

  return (<>

    <IonLoading onDidDismiss={(): void => setToast(false)} isOpen={toast} message={'Guardando...'} duration={200}/>
    <IonHeader className="headerTitle">
      <AppToolbar title={APPETITE_TITLE} />
    </IonHeader>
    <IonContent style={{height: '85%'}}>
      <div className={'appetite-manager'}>
        <IonGrid className={ANIMATION_FADE_IN_DOWN + ' risk-actions'}>
          <IonRow>
            <IonCol size="6">
              <IonButton className="apetite-btn" onClick={(): whatever => revertUnsavedChanges()}>Revertir</IonButton>
            </IonCol>
            <IonCol size="6" className="ion-align-self-end">
              <IonButton className="apetite-btn" onClick={(): whatever => updateRiskAppetite()}>Guardar</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <AppetiteBoard
          impacts={impacts}
          updateColor={updateColor}
          riskAppetites={riskAppetites}
        />
        <ImpactDescription impacts={impacts} />
      </div>
    </IonContent>

  </>

  );
};

export default AppetiteManager;
