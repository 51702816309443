import {IonAlert, IonBadge, IonButton, IonIcon} from '@ionic/react';
import React, {useState} from 'react';
import {RiskCategory} from '../../../types/enums/riskCategory';
import {filterById} from '../../smarts/RiskDisplayer/riskDisplayer';
import {getStorage} from '../../../helpers/localstorageUtil';
import {NOT_CATEGORY, RISK_URL_CATEGORIES, whatever} from '../../../constants';
import {Category} from '../../../types/category';
import {fileTray} from 'ionicons/icons';
import './Filter.scss';
import {ID} from '@datorama/akita';

interface Prop {
  categories: RiskCategory[] ;
  changeCategory: whatever;
  selected: RiskCategory[];
}

export const CategoryFilter: React.FunctionComponent<Prop> = (prop) => {
  const [isOpen, setOpen] = useState(false);
  const {selected, categories, changeCategory} = prop;

  const auxFunc = (id: ID[]): RiskCategory[] => {
    const aux: RiskCategory[] = getStorage(RISK_URL_CATEGORIES);
    return aux.filter((x: whatever) => id.some((j) => j === x.id));
  };
  return (
    <>
      <IonButton
        className="filter-btn"
        slot="end"
        onClick={(): void => {
          setOpen(true);
        }}
      >
        {selected.length ? <IonBadge color="dark">{selected.length}</IonBadge> : ''}
        <IonIcon slot="icon-only" icon={fileTray} />
      </IonButton>
      <IonAlert
        cssClass="custom-alert-class"
        isOpen={isOpen}
        onDidDismiss={() => setOpen(false)}
        header={'Categoría'}
        inputs={categories.map((x) => ({
          name: x,
          type: 'checkbox',
          label:
            (typeof x as number | string) === 'string' ?
              filterById<Category>(x, getStorage(RISK_URL_CATEGORIES)).title :
              x ?
              x :
              NOT_CATEGORY,
          value: x,
          checked: selected.some((y) => x === (y as whatever).id),
        }))}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'button-alert',
          },
          {
            text: 'Ok',
            cssClass: 'button-alert',
            handler: (x): void => changeCategory(auxFunc(x)),
          },
        ]}
      />
    </>
  );
};

export default CategoryFilter;
