import {QueryEntity} from '@datorama/akita';
import {companyStore, CompanyStore} from '../store/company.store';
import {CompanyState} from '../model/company.model';
import {Company} from '../../../interface/company/company';


export class CompanyQuery extends QueryEntity<CompanyState, Company> {
    public company$ = this.selectAll();
    public activeCompany$ = this.selectActive();
    public loading$ = this.selectLoading();
    constructor(protected companyStore: CompanyStore) {
      super(companyStore);
    }
}

export const companyQuery = new CompanyQuery(companyStore);
