export const TOKEN = 'token';
export const MESSAGE = 'No está autorizado para ingresar en esta aplicación, solicite acceso al administrador.';
export const EMPTY_FORM = 'Completar todos los campos.';
export const RISK_URL = process.env.REACT_APP_RISK_URL;
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const ACCOUNTS_URL = process.env.REACT_APP_ACCOUNTS_APP_URL;
export const CURRENT_APP_URL = process.env.REACT_APP_WEBAPP_URL;
export const MESSAGING_URL = process.env.REACT_APP_MESSAGING_URL;
export const LOGOUT_URL = 'logout';
export const RISK_URL_RISKS = 'risks';
export const RISK_URL_RISK_APPETITE = 'risk-appetite';
export const RISK_URL_INHERENT_IMPACT = 'impacts';
export const RISK_URL_CATEGORIES = 'categories';
export const RISK_URL_POSSIBLE_EFFECT = 'outcomes';
export const CONTROL_URL = 'controls';
export const CONTROL_URL_TYPES = 'controltypes';
export const TASK_URL = 'tasks';
export const POST = 'post-';
export const PUT = 'put-';
export const SUCCESS_CODE = 200;
export const SUCCESS_DATA_SAVED = 'Datos guardados correctamente';
export const SUCCESS_DATA_UPDATED = 'Datos editados correctamente ';
export const FORM_FIELD_LEFT = 'Existe uno o mas campos vacio(s)';
export const FORM_FIELD = 'Error al guardar datos';
export const MINUTE = 60000;
export const SHORT_TIME = 1000;
export const STANDARD_TIME = 2500;
export const LONG_TIME = 4000;
export const NOT_CATEGORY = 'Sin categoría';
export const NOT_IMPACT = 'Sin impacto';
export const COMFIRM_EDIT_MESSAGE = '¿Seguro desea editar?';
export const COMFIRM_ADD_MESSAGE = '¿Seguro desea guardar nuevo registro?';
export const BUTTON_SAVE_TEXT = 'Guardar';
export const BUTTON_UPDATE_TEXT = 'Actualizar';
export const URL_PROFILE_NOTIFICATION = 'profile';
export const TOKENKEY = 'token';
export const RTOKENKEY = 'rToken';
export const REG_EXPR = /Token expired|Signature verification failed|Not able to parse authentication token/i;
export const RISK_FORM_REG_EXPR = /description|category|inherentChance|inherentImpact|possibleEffects/;
export const NOT_VALID = /reqDescription|reqChance|reqCategory|reqImpact|reqEffect/i;
export const APPETITE_TITLE = 'Aceptación de Riesgo';
export const APPETITE_LABLES = [
  'Full recuperation short time',
  'Full recuperation large time',
  'Permanent corporal impact with light dis functioning',
  'Permanent corporal impact with severe dis functioning ',
  'Mortal',
];
export const APPETITE_NOT_ACCEPTED = 'NOT_ACCEPTED';
export const MODAL_CONTROL_RISK_TITLE = 'Listado de riesgos';
export const SCHEDULE_LIST_URL = 'scheduleditems';
export const SCHEDULE_URL = 'schedule';
export const SCREEN_CONSTANT = /107|235|256|319|347|370|393|395|464|476|485|558|563|576/;
export const DAYS_NAME = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
export const MONTH_NAME = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
export const IMAGE_LIST: { [key: string]: string } = {
  enero:
    'https://images.pexels.com/photos/289586/pexels-photo-289586.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
  febrero:
    'https://images.pexels.com/photos/176103/pexels-photo-176103.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
  marzo:
    'https://images.pexels.com/photos/668353/pexels-photo-668353.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
  abril:
    'https://images.pexels.com/photos/1405762/pexels-photo-1405762.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
  mayo: 'https://images.pexels.com/photos/280471/pexels-photo-280471.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
  junio: 'https://images.pexels.com/photos/7700/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
  julio: 'https://images.pexels.com/photos/276259/pexels-photo-276259.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
  agosto:
    'https://images.pexels.com/photos/276534/pexels-photo-276534.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
  septiembre:
    'https://images.pexels.com/photos/6332/coffee-cup-books-home.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
  octubre:
    'https://images.pexels.com/photos/305556/pexels-photo-305556.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
  noviembre:
    'https://images.pexels.com/photos/698170/pexels-photo-698170.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
  diciembre:
    'https://images.pexels.com/photos/1005058/pexels-photo-1005058.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
};
export const RISK_URL_PHOTOS = 'fotos';
export const URL_EVENTS = 'events';
export const DIRECTOR_DASHBOARD = 'Panel Directivo';
export const EMPLOYEE_DASHBOARD = 'Panel empleado';
export const RISK_LIST = 'Listado de Riesgos';
export const CONTROL_LIST = 'Listado  de Controles';
export const EVENT_LIST = 'Listado de Actos Inseguros';
export const WORK_PLAN = 'Plan de Trabajo';
export const REGISTER_RISK = 'Registrar Riesgo';
export const REGISTER_CONTROL = 'Registrar control';
export const REGISTER_EVENT = 'Registrar Acto Inseguro';
export const EDIT_INSECURE_ACT = 'Editar Acto Inseguro';
export const EDIT_CONTROL = 'Editar control';
export const EDIT_RISK = 'Editar riesgo';
export const DETAIL_RISK = 'Detalle del riesgo';
export const DETAIL_CONTROL = 'Detalles del control';
export const RISK_APETITE = 'Tolerancia de riesgo';
export const TITLE_COMPANY_PROFILE = 'Perfil de Empresa';
export const TITLE_USERS_LIST = 'Listado de empleados';
export const REGISTER_EMPLOYEE_FORM = 'Registrar empleado';
// eslint-disable-next-line max-len
export const INPUT_TYPE_FILE = 'application/pdf,application/docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const TITLE_MINIMAL_STANDARD = 'Estándares Mínimos';
export const URL_COMPANYPROFILE = 'companyprofile';
export const MINIMAL_STANDARD = 'minimalstandard';
export const RULE_COMPLANCE = 'rulecomplacence';
export const URL_DOCUMENT = 'documents';
export const URL_USER_LIST = 'tenant/users';
export const URL_PREFERENCES_USER = 'preference';
export const URL_USER_AUTHORIZATION = 'authorization';
export const WITH_RIGHTS = 'WithRights';
export const USER_ROLE = 'Admin';
export const URL_PROFLE_ADMIN = 'profileadmin';
export const URL_USER_AUTHORIZATION_BY_ID = 'authorizationUser';
export const NOTIFICATION_URL = 'notification';
export const BUTTON_ANIMATION_CLASS = 'animated wobble delay-3s faster';
export const CARD_ANIMATION_CLASS = 'animated bounceInLeft delay-200ms fast';
export const IMAGE_ANIMATION_CLASS = 'animated bounceIn delay-200ms faster';
export const CARD_ANIMATION_CLASS_RIGHT = 'animated bounceInRight delay-200ms faster';
export const ANIMATION_FADE_IN_DOWN = 'animated fadeInDown delay-200ms faster';
export const ANIMATION_FADE_IN_UP = 'animated fadeInUp delay-200ms faster';
export const ANIMATION_FADE_IN_RIGHT = 'animated fadeInRight delay-200ms faster';
export const RENEW_TOKEN = 'renew';
export const IMG_SMALL_URL = 'https://beamarcms.s3.amazonaws.com/orig/';
// export const IMG_SMALL_URL = 'https://sibla.co/small/';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type whatever = any;
export const TITLE_EVIDENCES = 'Evidencias';
export const URL_ARCHIVE_STRUCTURE = 'archiveStructure';
export const API_URL_EVIDENCES = 'evidences';
export const URL_MEDIA = 'media';
export const GUIDE_URL = process.env.REACT_APP_GUIDE_URL;
export const PROF = 'cur_prf';
