/* eslint-disable no-console */
import {Plugins, FilesystemDirectory} from '@capacitor/core';

import {FileOpener} from '@ionic-native/file-opener';

export const createFile = (base64: string, fileName: string): void => {
  const {Filesystem} = Plugins;
  try {
    Filesystem.writeFile({
      path: fileName,
      data: base64,
      directory: FilesystemDirectory.Documents,
    }).then((res) => {
      console.log('Success create file: ', res);
      Filesystem.getUri({
        directory: FilesystemDirectory.Documents,
        path: fileName,
      }).then(
          (getUriResult) => {
            const path = getUriResult.uri;
            FileOpener.open(path, 'application/pdf')
                .then(() => console.log('Success open'))
                .catch(() => {
                  const downloadLink = document.createElement('a');
                  downloadLink.href = base64;
                  downloadLink.download = fileName;
                  downloadLink.click();
                });
          },
          (error) => {
            console.log(error);
          },
      );
    });
  } catch (e) {
    console.error('Error create File: ', e);
  }
};

export const downloadPDF = (pdf: string, nameFile: string): void => {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const fileName = `${nameFile}`;
  createFile(linkSource, fileName);
};
