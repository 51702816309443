import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import ErrorPage404 from './components/dummies/ErrorPage/ErrorPage404';


ReactDOM.render(<ErrorPage404><App /></ErrorPage404>, document.getElementById('root'));

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);

