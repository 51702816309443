import React from 'react';
import {IonIcon, IonText} from '@ionic/react';
import './InfoEmptyDataIcon.scss';

interface Props {
  emojiDec?: string;
  message?: string;
  icon?: string;
  stails? : string;
}

const center = {
  margin: 'auto',
  button: '50%',
};

// eslint-disable-next-line react/prop-types
const EmptyDataIcon: React.FC<Props> = ({emojiDec, message, icon, stails}) => (
  <div style={center} className={stails ? stails + ' animated bounceInDown delay-1s fast' : ' animated bounceInDown delay-1s fast'}>
    {emojiDec ? <div
      style={{
        fontSize: '75px',
        textAlign: 'center',
        marginTop: '20px',
      }}
      dangerouslySetInnerHTML={{__html: '&#' + emojiDec + ';'}}
    /> : null}
    <div className="card-icon">
      { icon ? <IonIcon icon={icon} slot="center" /> : null}
    </div>

    <IonText color="medium" style={{textAlign: 'center'}}>
      <h5 style={{width: '60%', margin: 'auto'}}>Aún no tiene {message ? message : ''}</h5>
    </IonText>
  </div>
);
export default EmptyDataIcon;
