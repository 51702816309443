import {Notifications} from '../../../../types/notifications';

export const notificationTestList: Notifications[] = [
  {
    title: 'Notification Title 1',
    description: 'Notification description 1 this is only a test for notifications in Sibla App',
    state: 'OPEN',
    date: new Date(),
    open: false,
  },
  {
    title: 'Notification Title 2',
    description: 'Notification description 2 this is only a test for notifications in Sibla App',
    state: 'OPEN',
    date: new Date(),
    open: false,
  },
  {
    title: 'Notification Title 3',
    description: 'Notification description 3 this is only a test for notifications in Sibla App',
    state: 'CLOSED',
    date: new Date(),
    open: false,
  },
  {
    title: 'Notification Title 4',
    description: 'Notification description 4 this is only a test for notifications in Sibla App',
    state: 'OPEN',
    date: new Date(),
    open: false,
  },
  {
    title: 'Notification Title 5',
    description: 'Notification description 5 this is only a test for notifications in Sibla App',
    state: 'OPEN',
    date: new Date(),
    open: false,
  },
  {
    title: 'Notification Title 6',
    description: 'Notification description 6 this is only a test for notifications in Sibla App',
    state: 'CLOSED',
    date: new Date(),
    open: false,
  },
  {
    title: 'Notification Title 7',
    description: 'Notification description 7 this is only a test for notifications in Sibla App',
    state: 'OPEN',
    date: new Date(),
    open: false,
  },
  {
    title: 'Notification Title 8',
    description: 'Notification description 8 this is only a test for notifications in Sibla App',
    state: 'OPEN',
    date: new Date(),
    open: false,
  },
  {
    title: 'Notification Title 9',
    description: 'Notification description 9 this is only a test for notifications in Sibla App',
    state: 'OPEN',
    date: new Date(),
    open: false,
  },
];
