import React from 'react';

// COMPONENTS
import RiskToast from './smarts/Register/RiskForm/RiskToast';
import RegisterControl from './smarts/Register/RegisterControl/Register';
import RiskRegister from '../components/smarts/RiskRegister/RiskRegister';
import RegisterEmployeeForm from './smarts/users/infrastructure/components/smart/RegisterUserForm/RegisterEmployeeForm';

import AppetiteManager from './smarts/risk-components/appetite/appetiteManager/AppetiteManager';
const RiskDetail = React.lazy(() => import('./smarts/Risk/RiskDetail/RiskDetail'));
const RiskControl = React.lazy(() => import('./smarts/Risk/RiskControl/RiskControlContainer/RiskControl'));
const Menu = React.lazy(() => import('./dummies/Menu/Menu'));
const WorkPlan = React.lazy(() => import('./smarts/risk-components/WorkPlan/WorkPlan'));
const Logout = React.lazy(() => import('./dummies/UtilComponents/Logout'));
const DirectorDashboard = React.lazy(() => import('./smarts/DirectorDashboard/DirectorDashboard'));
const InsecureAct = React.lazy(() => import('./smarts/risk-components/RegisterInsecureAct/InsecureAct'));
const InsecureActList = React.lazy(() => import('./smarts/risk-components/InsecureAct/InsecureActList'));
const UserAccount = React.lazy(() => import('./dummies/UserAccount/UserAccount'));
const RefreshToken = React.lazy(() => import('./dummies/RefreshToken/refreshToken'));
const CompanyProfile = React.lazy(() => import('./dummies/CompanyProfile/CompanyProfile'));
const LoadApp = React.lazy(() => import('./dummies/Menu/LoadApp'));
const EvidencePage = React.lazy(() => import('./dummies/pages/evidence.page'));
const ControlDetails = React.lazy(() => import('./smarts/Control/ControlDetails'));
const MinimalStandardDetail = React.lazy(() => import('./dummies/MinimalStandard/minimalStandardDetail'));
const MinimalStandardContainer = React.lazy(() => import('./smarts/MinimalStandard/MinimalStandardContainer'));
const SlideTutorials = React.lazy(() => import('./dummies/SlideTutorials/components/smart/SlideTutorials'));
const Users = React.lazy(() => import('./smarts/users/infrastructure/components/smart/Users/Users'));

export {
  RiskToast,
  RegisterControl,
  RiskRegister,
  RegisterEmployeeForm,
  AppetiteManager,
  RiskDetail,
  RiskControl,
  Menu,
  WorkPlan,
  Logout,
  DirectorDashboard,
  InsecureAct,
  UserAccount,
  InsecureActList,
  RefreshToken,
  CompanyProfile,
  LoadApp,
  EvidencePage,
  ControlDetails,
  MinimalStandardDetail,
  MinimalStandardContainer,
  SlideTutorials,
  Users,
};
