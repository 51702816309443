import {Plugins} from '@capacitor/core';

const {Storage} = Plugins;

export const setObject = async (key: string, data: object): Promise<void> => {
  await Storage.set({key: key, value: JSON.stringify(data)});
};


export const getObject = async function<T>(key: string): Promise<T> {
  const aux = await Storage.get({key: key});
  const ret = aux.value as string;
  return JSON.parse(ret);
};
