/* eslint-disable @typescript-eslint/no-useless-constructor */
import {EntityStore, StoreConfig} from '@datorama/akita';
import {EmployeeState} from '../model/users.type';

@StoreConfig({name: 'employee', idKey: 'id'})
export class EmployeeStore extends EntityStore<EmployeeState> {
  constructor() {
    super();
  }
}

export const employeeStore = new EmployeeStore();
