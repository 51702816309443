import React, {useState} from 'react';
import colorPossibilityOfOccurrance from '../../../helpers/riskColor';
import {IonCard, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding} from '@ionic/react';
import {trash} from 'ionicons/icons';
import RiskDisplayer from '../RiskDisplayer/riskDisplayer';
import {DeleteRiskToast} from '../Risk/RiskDetail/DeleteRisk';
import {Risk} from '../../../types/risk';
import {ID} from '@datorama/akita';
import './ControlDetail.scss';


interface RiskListProps {
    RiskList: Risk[];
    checkDelete?: (toDelete: ID) => void;
    longClick?: Function;
}

export const CreateRiskList: React.FC<RiskListProps> = ({RiskList, checkDelete, longClick}) => {
  const [selectRiskId, setSelectedRiskId] = useState<ID>();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const deleteRisk = async (statusDelete: boolean): Promise<void> => {
    if (statusDelete && checkDelete) {
      checkDelete(selectRiskId as ID);
    }
  };
  return (
    <>
      {RiskList.map((risk, i) => {
        risk = {...risk, color: colorPossibilityOfOccurrance(risk)};
        return (
          <div key={i}>
            <IonItemSliding disabled={!checkDelete} className={'itemSlide'}>
              <IonItemOptions side="start">
                <IonItemOption
                  onClick={(): void => {
                    // deleteRisk(risk.id as number);
                    setSelectedRiskId(risk.id);
                    setConfirmDelete(true);
                  }}
                  color="danger"
                >
                  <IonIcon slot="start" icon={trash}/>
                </IonItemOption>
              </IonItemOptions>
              <IonItem lines="none" className={'itemRisk'}>
                <IonCard style={{borderRadius: '9px', background: '#EAEAEA', boxShadow: 'none'}}
                  className="card animated bounceInLeft delay-1s fast" button>
                  <RiskDisplayer risk={risk} longClick={longClick}/>
                </IonCard>
              </IonItem>
            </IonItemSliding>
          </div>
        );
      })}
      {selectRiskId ? (
                <DeleteRiskToast
                  isOpen={confirmDelete}
                  onClose={(): void => {
                    setConfirmDelete(false);
                  }}
                  onDeleteRisk={deleteRisk}
                />
            ) : null}
    </>
  );
};
