import {notLoggedIn} from './service';
import {whatever} from '../constants';
import {Observable, of} from 'rxjs';

export const setStorage = (key: string, value: whatever): void => {
  const type: string = typeof value;
  if (type === 'object') localStorage.setItem(key, JSON.stringify(value));
  else localStorage.setItem(key, value as string);
};

export const clearStorage = <T>(key: string): T => {
  const result: whatever = localStorage.removeItem(key);
  try {
    return JSON.parse(result) as T;
  } catch (error) {
    return result;
  }
};

export const clearListStorage = (): void => {
  if (notLoggedIn()) localStorage.clear();
};

export const getStorage = <T>(key: string): T => {
  const result: whatever = localStorage.getItem(key);
  try {
    return JSON.parse(result) as T;
  } catch (error) {
    return result;
  }
};

export const getStorageObs = <T> (key: string): Observable<T> => {
  const result: whatever = localStorage.getItem(key);
  return of(JSON.parse(result) as T);
};
