import {checkExpiredToken, deletehttp, get, post, put} from './httpAxios';
import {
  RISK_URL_RISKS,
  POST,
  RISK_URL_CATEGORIES,
  RISK_URL_INHERENT_IMPACT,
  RISK_URL_POSSIBLE_EFFECT,
  PUT,
  CONTROL_URL,
  REG_EXPR,
  RISK_URL_PHOTOS,
  RISK_URL_RISK_APPETITE, whatever,
} from '../constants';
import {setStorage} from '../helpers/localstorageUtil';
import {Risk} from '../types/risk';
import {Media as RiskPhotos} from '../types/media';
import {from} from 'rxjs';
import {map, tap, switchMap, catchError} from 'rxjs/operators';
import {riskStore, RiskStore} from '../akita/stores/risk.store';
import {ID} from '@datorama/akita';


const checkStorages = (res: whatever, storage?: boolean): void =>
    storage && res.status && !REG_EXPR.test(res.data) ? res.data : [];


export const addPhotos = async (riskId: ID, data: RiskPhotos[]): Promise<whatever> => {
  let res;
  try {
    res = await post(`${RISK_URL_RISKS}/${riskId}/${RISK_URL_PHOTOS}`, data);
  } catch (e) {
    res = e;
  }
  return res;
};

export const getPhotos = async (riskId: ID ): Promise<whatever> => {
  let res;
  try {
    res = await get(`${RISK_URL_RISKS}/${riskId}/${RISK_URL_PHOTOS}`);
  } catch (e) {
    return null;
  }
  return res;
};

export const removePhotos = async (riskId: number, photoId: number): Promise<whatever> => {
  let res;
  try {
    res = await deletehttp(`${RISK_URL_RISKS}/${riskId}/${RISK_URL_PHOTOS}/${photoId}`);
  } catch (e) {
    res = e;
  }
  return res;
};

export class RiskService {
  constructor(private riskStore: RiskStore) {}

  public getRisks(id?: ID, limit?: string): whatever {
    return from(get(`${RISK_URL_RISKS}${id ? '/' + id : limit ? `?apetite=${limit}` : ''}`))
        .pipe(tap((x) => {
          if (!id && !x.message) {
            this.riskStore.set(x.data);
          }
          // todo: remove this after risk dynamodb migration in backend
          if (!id && x?.message === 'error') this.getRisks();

          if (id) {
            this.riskStore.update(x.data.id, () => ({...x.data}));
            localStorage.setItem('risk-data', JSON.stringify(x.data.controls));
          }
        }),
        catchError((err) => err),
        );
  }

  public getRiskCategory(storage?: boolean): whatever {
    return from(get(RISK_URL_CATEGORIES))
        .pipe(
            map((x) => x.data),
            tap( (x) => {
              if (storage) {
                setStorage(RISK_URL_CATEGORIES, x);
                checkStorages(x, storage);
                return;
              }
              // this.riskStore.set(x.data);
            }),
        );
  }

  public getRiskInherentImpact(storage?: boolean): whatever {
    return from(get(RISK_URL_INHERENT_IMPACT))
        .pipe(
            map((x) => x.data),
            tap( (x) => {
              if (storage) {
                setStorage(RISK_URL_INHERENT_IMPACT, x);
                checkStorages(x, storage);
                return;
              }
              this.riskStore.set(x.data);
            }),
        );
  }

  public getRiskPossibleEffect(storage?: boolean): whatever {
    return from(get(RISK_URL_POSSIBLE_EFFECT))
        .pipe(
            map((x) => x.data),
            tap( (x) => {
              if (storage) {
                setStorage(RISK_URL_POSSIBLE_EFFECT, x);
                checkStorages(x, storage);
                return;
              }
              this.riskStore.set(x.data);
            }),
        );
  }

  public getGeneral(API: string, storage?: boolean): whatever {
    return from(get(API))
        .pipe(
            map((x) => x.data),
            tap( (x) => {
              if (storage) setStorage(API, x);
              this.riskStore.set(x.data);
            }),
        );
  }


  public getRiskAppetites(storage?: boolean): whatever {
    return from(get(RISK_URL_RISK_APPETITE))
        .pipe(
            map((x) => x.data),
            tap( (x) => {
              if (storage) {
                setStorage(RISK_URL_RISK_APPETITE, x);
                return;
              }
              this.riskStore.set(x);
            }),
        );
  }

  public saveRiskAppetites(data: Risk ): whatever {
    localStorage.setItem('risk-appetite', JSON.stringify(data));
    return from(post(RISK_URL_RISK_APPETITE, data));
  }

  public postGeneral(API: string, data: Risk): whatever {
    return from(post(API, data))
        .pipe(
            tap( (x) => {
              setStorage(`${POST}${API}`, data);
              checkExpiredToken(x.data);
              localStorage.removeItem(`${POST}${API}`);
              data = {...data, id: x.data.message};
              localStorage.setItem('risk_data', JSON.stringify(data));
              this.riskStore.add(data);
              this.setActive(data.id as ID);
            }),
        );
  }

  public saveRisk(data: Risk): whatever {
    return from(post(RISK_URL_RISKS, data))
        .pipe(
            tap( (x) => {
              setStorage(`${RISK_URL_RISKS}`, data);
              checkExpiredToken(x.data);
              localStorage.removeItem(`${POST}${RISK_URL_RISKS}`);
              this.riskStore.add({...data, id: x.data.message});
              this.setActive(x.data.message);
            }),
        );
  }


  public updateRisk(data: whatever, id: ID): whatever {
    return from(put(`${RISK_URL_RISKS}/${id}`, data))
        .pipe(
            tap( (x) => {
              setStorage(`${PUT}${RISK_URL_RISKS}`, data);
              checkExpiredToken(x.data);
              localStorage.removeItem(`${PUT}${RISK_URL_RISKS}`);
              this.riskStore.update(x.data.id, () => x);
              this.setActive(x.data.id);
            }),
        );
  }

  public addControlToRisk(riskId: ID, controlId: ID): whatever {
    return from(post(`${RISK_URL_RISKS}/${riskId}/${CONTROL_URL}/${controlId}`))
        .pipe(
            tap( () => {
              this.getRisks(riskId);
            }),
        );
  }

  public removeControlToRisk(riskId: ID, controlId: ID): whatever {
    return from(deletehttp(`${RISK_URL_RISKS}/${riskId}/${CONTROL_URL}/${controlId}`))
        .pipe(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            map( (x) => {
              return this.riskStore.remove(riskId);
            }),
        );
  }

  public removeControlFromRisk(riskId: ID, controlId: ID): whatever {
    return from(deletehttp(`${RISK_URL_RISKS}/${riskId}/${CONTROL_URL}/${controlId}`))
        .pipe(
            switchMap((value) => {
              // eslint-disable-next-line no-console
              console.log('switchMap Value 2: ', value);
              return this.getRisks(riskId);
            }),
        );
  }

  public removeRisk(riskId: ID): whatever {
    return from(deletehttp(`${RISK_URL_RISKS}/${riskId}`))
        .pipe(
            tap( () => {
              this.riskStore.remove([riskId]);
            }),
        );
  }

  public addPhotos(riskId: ID, data: RiskPhotos[]): whatever {
    return from(post(`${RISK_URL_RISKS}/${riskId}/${RISK_URL_PHOTOS}`, data))
        .pipe(
            tap( (x) => {
              this.riskStore.add(x.data);
            }),
        );
  }

  public removePhotos(riskId: ID, photoId: ID): whatever {
    return from(deletehttp(`${RISK_URL_RISKS}/${riskId}/${RISK_URL_PHOTOS}/${photoId}`))
        .pipe(
            tap( (x) => {
              this.riskStore.remove(x.data);
            }),
        );
  }

  public setActive(id: ID | null): void {
    this.riskStore.setActive(id);
  }

  public removeActive(): void {
    this.riskStore.setActive(null);
  }

  public updateActive(newState: Risk): void {
    this.riskStore.updateActive(newState);
  }

  public handleGetRiskList(): whatever {
    return this.getRiskAppetites(true).pipe(
        switchMap(()=> this.getRisks()),
    );
  }
}

export const riskService = new RiskService(riskStore);
