import {Observable, Subscription} from 'rxjs';
import {controlQuery, ControlQuery} from '../query/control.query';
import {Control} from '../../types/control';
import {controlService, ControlService} from '../../services/control.service';
import {ID} from '@datorama/akita';
import {Epp} from '../../types/epp';


export class ControlFacade {
    public readonly control$ = this.controlQuery.control$;
    public readonly activeControl$ = this.controlQuery.activeControl$ as Observable<Control>;
    public readonly loading$ = this.controlQuery.loading$;
    private readonly subscriptions = new Subscription();

    constructor(private controlQuery: ControlQuery, private controlService: ControlService) {}


    public getControl(): void {
      this.subscriptions.add(this.controlService.getControl().subscribe());
    }

    public saveControl(dataControl: Control): void {
      this.subscriptions.add(this.controlService.saveControl(dataControl).subscribe());
    }

    public getControlById(controlId: ID): void {
      this.subscriptions.add(this.controlService.getControlById(controlId).subscribe());
    }

    public updateControl(controlId: Control): void {
      this.subscriptions.add(this.controlService.updateControl(controlId).subscribe());
    }
    public deleteControl(controlId: ID): void {
      this.subscriptions.add(this.controlService.deleteControl(controlId).subscribe());
    }
    public getControlRisks(controlId: ID): void {
      this.subscriptions.add(this.controlService.getControlRisks(controlId).subscribe());
    }
    public getControlTypes(storage?: boolean): void {
      this.subscriptions.add(this.controlService.getControlTypes(storage).subscribe());
    }
    public saveEpp(controlId: Control, data: Epp): void {
      this.subscriptions.add(this.controlService.saveEpp(controlId, data).subscribe());
    }

    public setLoading(flag: boolean): void {
      this.controlService.setLoadingState(flag);
    }


    public setActive(riskId: ID | null): void {
      this.controlService.setActive(riskId);
    }
    public killSubscriptions(): void {
      this.subscriptions.unsubscribe();
    }
}

export const controlFacade = new ControlFacade(controlQuery, controlService);
