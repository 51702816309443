import {RTOKENKEY, TOKENKEY, USER_ROLE, whatever, WITH_RIGHTS} from '../constants';

export function getToken(): whatever {
  return localStorage.getItem(TOKENKEY);
}

export function notLoggedIn(): whatever {
  return !getToken();
}

export function setToken(token: whatever): whatever {
  if (token.map) token = token.map;
  if (token.withRights) localStorage.setItem(WITH_RIGHTS, token.withRights);
  if (token.Administrator) localStorage.setItem(USER_ROLE, token.Administrator);
  localStorage.setItem(TOKENKEY, token.token);
  localStorage.setItem(RTOKENKEY, token.rToken);
}

export function removeToken(): whatever {
  localStorage.removeItem(TOKENKEY);
  localStorage.removeItem(RTOKENKEY);
}
