import {employeeStore, EmployeeStore} from '../store/employee.store';
import {from, Observable} from 'rxjs';
import {get, post, put} from '../../../../../services/httpAxios';
import {EMPLOYEE_URL} from '../../util/employee.constant';
import {tap} from 'rxjs/operators';
import {UsersType} from '../model/users.type';
import {ACCOUNTS_URL} from '../../../../../constants';
import {ID} from '@datorama/akita';

export class EmployeeService {
  constructor(private employeeStore: EmployeeStore) {}

  public getEmployees(): Observable<whatever> {
    return from(get(EMPLOYEE_URL)).pipe(
        tap((x: whatever) => {
          if (x.status === 200) employeeStore.set(x.data.data);
        }),
    );
  }

  public updateEmployee(employeeId: ID, employee: UsersType): Observable<whatever> {
    return from(put(`${EMPLOYEE_URL}/${employeeId}`, employee)).pipe(
        tap(() => {
          this.employeeStore.update(employee);
        }),
    );
  }

  public createEmployee(employee: UsersType, invitation: boolean): Observable<whatever> {
    return from(post(`${EMPLOYEE_URL}?invitation=${invitation}&redirect_url=${ACCOUNTS_URL}`, employee)).pipe(
        tap((x: whatever) => {
          if (x.status === 200) this.employeeStore.add([x.data.data]);
        }),
    );
  }
}

export const employeeService = new EmployeeService(employeeStore);
