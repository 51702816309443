/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-function-return-type */
import {checkExpiredToken, get, post, put} from './httpAxios';
import {
  AUTH_URL, IMG_SMALL_URL,
  RENEW_TOKEN, URL_PREFERENCES_USER,
  URL_PROFILE_NOTIFICATION,
  URL_USER_AUTHORIZATION,
  URL_USER_AUTHORIZATION_BY_ID, whatever,
  WITH_RIGHTS,
} from '../constants';
import {getStorage} from '../helpers/localstorageUtil';
import {UserAuthorization} from '../types/userAuthorization';
import {from, Observable} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ID} from '@datorama/akita/lib/types';
import {UserProfile} from '../types/userProfile';


export const getUser = async (id?: ID): Promise<whatever> => {
  let res;
  try {
    res = await get(`${URL_PREFERENCES_USER}${id ? '/' + id : ''}`, AUTH_URL);
    checkExpiredToken(res.data);
  } catch (e) {
    return e;
  }
  return res.status === 200 ? res : [];
};


export const saveAuthorization = async () => {
  const res = await post(URL_USER_AUTHORIZATION);
  try {
    checkExpiredToken(res.data);
  } catch (e) {
    return e;
  }
  return res;
};

export const getWithRights = () => {
  return getStorage(WITH_RIGHTS);
};

export const listAuthorizationUser = async () => {
  let res;
  try {
    res = await get(`${URL_USER_AUTHORIZATION}`);
    checkExpiredToken(res.data);
  } catch (e) {
    return e;
  }
  return res.status === 200 ? res : [];
};

export const changeRights = async (idAuth: ID, data: UserAuthorization) => {
  let res;
  try {
    res = await put(`${URL_USER_AUTHORIZATION}/${idAuth}`, data);
    checkExpiredToken(res.data);
  } catch (e) {
    return e;
  }
  return res;
};

export const getAuthUser = (): Observable<any> => {
  return from(get(URL_USER_AUTHORIZATION_BY_ID)).pipe(map((user)=>{
    return user;
  }));
};

export const renewToken = async () => {
  let res;
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('rToken'),
  };
  try {
    res = await get(RENEW_TOKEN, AUTH_URL, headers);
    checkExpiredToken(res.data);
  } catch (e) {
    return e;
  }
  return res;
};

export const getProfile = async (stateLogin?: boolean, token?: string): Promise<whatever> => {
  let headers = undefined;
  if (token) {
    headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    };
  }
  const response = await get(`${URL_PROFILE_NOTIFICATION}${stateLogin ? '?stateLogin=true' : ''}`,
      undefined, headers ?? undefined);
  try {
    checkExpiredToken(response.data);
  } catch (e) {
    return e;
  }
  return response;
};

export const getProfileObs = (): Observable<whatever> => {
  return from(get(URL_PROFILE_NOTIFICATION)).pipe(
      map((response) => response.data),
  );
};

export const getUserPreference = (): Observable<whatever> => {
  return from(getUser()).pipe(
      map((response) => response.data),
  );
};

export const updateUserProfile = (user: UserProfile): Observable<whatever> => {
  return from(put(URL_PROFILE_NOTIFICATION, user)).pipe(
      map((res) => res.data),
  );
};

export const getUpdatePreferenceProfile = (): Observable<any> => {
  return getProfileObs().pipe(
      switchMap((r1) => {
        return getUserPreference().pipe(
            switchMap((r2) => {
              let profileAux = {...r1, userName: r2.displayName};
              if (r2.email) profileAux = {...profileAux, email: r2.email};
              if (r2.cellphone) profileAux = {...profileAux, phoneNumber: r2.cellphone};
              if (r2.imageUrl) profileAux = {...profileAux, avatarUrl: `${IMG_SMALL_URL}${r2.imageUrl}`};
              return updateUserProfile(profileAux).pipe(
                  switchMap(() => {
                    localStorage.setItem('prf', JSON.stringify(profileAux));
                    return getAuthUser();
                  }),

              );
            }),
        );
      }),
      catchError((err) => err),
  );
};
