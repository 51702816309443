/* eslint-disable @typescript-eslint/no-useless-constructor */
import {EntityStore, StoreConfig} from '@datorama/akita';
import {RiskState} from '../models/risk.model';

@StoreConfig({name: 'risk', idKey: 'id'})
export class RiskStore extends EntityStore<RiskState> {
  constructor() {
    super();
  }
}

export const riskStore = new RiskStore();
