/* eslint-disable @typescript-eslint/no-useless-constructor */
import {EntityStore, StoreConfig} from '@datorama/akita';
import {ControlState} from '../models/control.model';

@StoreConfig({name: 'control', idKey: 'id'})
export class ControlStore extends EntityStore<ControlState> {
  constructor() {
    super();
  }
}

export const controlStore = new ControlStore();
