import {QueryEntity} from '@datorama/akita';
import {riskStore, RiskStore} from '../stores/risk.store';
import {RiskState} from '../models/risk.model';
import {Risk} from '../../types/risk';


export class RiskQuery extends QueryEntity<RiskState, Risk> {
    public risk$ = this.selectAll();
    public activeRisk$ = this.selectActive();
    constructor(protected riskStore: RiskStore) {
      super(riskStore);
    }
}

export const riskQuery = new RiskQuery(riskStore);
