/* eslint-disable valid-jsdoc */
import {
  AUTH_URL,
  IMAGE_LIST,
  LOGOUT_URL,
  URL_PROFLE_ADMIN,
  USER_ROLE,
  whatever,
} from '../constants';
import {DisplayerTitleDate, ScheduleTaskDisplayer} from '../types/scheduleItem';
import {Task} from '../types/Task';
import moment from 'moment';
import {getStorage} from '../helpers/localstorageUtil';
import {checkExpiredToken, get, post, put} from './httpAxios';
import {from} from 'rxjs';
import {tap} from 'rxjs/operators';


export const capturerSlideEvent = (id: string): void => {
  const aux: whatever = document.getElementById(id);
  if (aux) aux.closeOpened();
};

export const capitalizeText = (text?: string | null, date?: whatever, format?: string): string => {
  if (text) {
    return (text.charAt(0).toUpperCase() + text.slice(1, text.length)).replace('.', '');
  }
  if (date && format) {
    capitalizeText(moment(new Date(date.date as string)).format(format));
  }
  return '';
};


export const randomImage = (month: string): string => {
  return IMAGE_LIST[month];
};

export const generateScheduleListDisplayer = (list: Task[]): ScheduleTaskDisplayer[] => {
  const sorted: ScheduleTaskDisplayer[] = [];
  if (sorted.length === 0) sorted.push({date: list[0].scheduleVO ? list[0].scheduleVO.date : '', Tasks: [list[0]]});
  for (let i = 1; i < list.length; i++) {
    let aux = false;
    sorted.map((e: whatever) => {
      if (list[i].scheduleVO && list[i].scheduleVO?.date && e.date && e.date === list[i].scheduleVO?.date) {
        aux = true;
        e.Tasks.push(list[i]);
      }
      return e;
    });
    if (!aux) {
      sorted.push({date: list[i].scheduleVO?.date, Tasks: [list[i]]});
    }
  }
  return sorted;
};

export const generateList = (list: Task[]): DisplayerTitleDate[] => {
  const aux: ScheduleTaskDisplayer[] = generateScheduleListDisplayer(list);
  /**
     * @param index
     * in of local development backend returns and spanish date with generate and invalid date error
     * this wont happend in productions
     */
  const dateConversion = (index: number): whatever => {
    return moment(new Date(aux[index].date as string)).format('MMMM YYYY');
  };


  const agrupedByMonth: DisplayerTitleDate[] = [];
  const auxMonthName: string[] = [];
  if (auxMonthName.length === 0) auxMonthName.push(dateConversion(0));

  for (let i = 1; i < aux.length; i++) {
    for (let j = 0; j < auxMonthName.length; j++) {
      const compareVar = dateConversion(i);
      const res = auxMonthName.find((x) => {
        return x === compareVar;
      });
      if (!res) auxMonthName.push(compareVar);
    }
  }

  const data = aux.map((data) => {
    return moment(new Date(data.date as string)).format('YYYY-MM-DD');
  });

  const orderData = data.sort((a, b) => {
    if (a < b) return 1;
    if (a > b) return -1;
    return 0;
  });
  const converteDataNew = orderData.map((data) => {
    return moment(new Date(data as string)).format('MMMM YYYY');
  });
  const newData: whatever = new Set(converteDataNew);
  const arryData = [...newData];
  for (const iterator of arryData) {
    const res = aux.filter((my) => {
      return iterator === moment(new Date(my.date as string)).format('MMMM YYYY');
    });
    if (res.length > 0) agrupedByMonth.push({monthname: iterator, items: res});
  }

  return agrupedByMonth;
};

export const getAdmin = (): whatever => {
  return getStorage(USER_ROLE);
};

export const setAdmin = async (): Promise<whatever> => {
  const res = await put(URL_PROFLE_ADMIN);
  try {
    checkExpiredToken(res.data);
  } catch (e) {
    return e;
  }
  return res;
};

export const getStoreUsage = async (): Promise<whatever> => {
  let res;
  try {
    res = await get(`store`);
    checkExpiredToken(res.data);
  } catch (e) {
    return e;
  }
  return res.status === 200 ? res : undefined;
};


export const logOut = (): void => {
  from(post(`${LOGOUT_URL}`, undefined, AUTH_URL)).pipe(
      tap((res) => res.data),
  ).subscribe();
};
