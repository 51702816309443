/* eslint-disable @typescript-eslint/no-unused-vars */
import {checkExpiredToken, deletehttp, get, post, put} from '../../../../services/httpAxios';
import {URL_COMPANYPROFILE, URL_DOCUMENT, URL_MEDIA, whatever} from '../../../../constants';
import {Company} from '../../../../Domain/interface/company/company';
import {Media} from '../../../../types/media';
import {ID} from '@datorama/akita';
import {from, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {companyStore, CompanyStore} from '../../../../Domain/entities/company/store/company.store';
import {downloadPDF} from '../../../../helpers/dowloadPdf';
import {CompanyRepository} from './company.repository';


export const getDocument = async (documentId: ID, detail?: boolean): Promise<whatever> => {
  let res;
  try {
    res = await get(`${URL_MEDIA}/${documentId}${detail ? '?detail=true' : ''}`);
    checkExpiredToken(res.data);
  } catch (e) {
    return e;
  }
  return res.status === 200 ? res : [];
};


export class CompanyService implements CompanyRepository {
  constructor(private companyStore: CompanyStore) {}

  listAll(): Observable<Company[]> {
    return from(get(URL_COMPANYPROFILE)).pipe(
        tap((resp: whatever) =>{
          if (resp.status === 200 ) this.companyStore.add(resp.data);
        }),
    );
  }

  updateCompany(id: ID, infoCompany: Company): Observable<void> {
    return from(put(`${URL_COMPANYPROFILE}/${id}`, infoCompany)).pipe(
        tap((resp: whatever) =>{
          if (resp.status === 200 ) this.companyStore.update(id, ()=> ({id, ...resp.data}));
        }),
    );
  }
  saveEvidenceCompany(companyId: ID, Evidences: Media[] ): Observable<whatever> {
    return from( post(`${URL_COMPANYPROFILE}/${companyId}/documentList`, Evidences)).pipe(tap((res: whatever) => {
      if (res.status === 200 ) {
        this.companyStore
            .update(companyId, (companyProfile: Company) => {
              if (companyProfile.documents) {
                return {...companyProfile,
                  documents: [...res.data, ...companyProfile.documents]};
              }
              return {...companyProfile,
                documents: res.data};
            });
      }
    }));
  }

  getDocumentCompany(documentId: ID, detail?: boolean): Observable<whatever> {
    return from(get(`${URL_MEDIA}/${documentId}${detail ? '?detail=true' : ''}`)).pipe(tap((document)=>{
      if (document.status === 200 && document.data) {
        downloadPDF(document.data.docFile ?? document.data.base64, document.data.mediaName);
      }
    }));
  }

  removeDocumentCompany(idCompany: ID, documentId: ID): Observable<whatever> {
    return from(deletehttp(`${URL_DOCUMENT}/${documentId}`)).pipe(map((res: whatever) =>{
      if (res.status === 200) {
        this.companyStore.update(idCompany, (companyProfile)=>{
          if (companyProfile.documents) {
            const documents = companyProfile.documents.filter((document) => document.id !== documentId);
            return {...companyProfile, documents};
          }
        } );
      }
    }));
  }

  delete(id: ID): Observable<void> {
    return new Observable<void>();
  }

  getById(id: ID): Observable<Company> {
    return new Observable<undefined>() as unknown as Observable<Company>;
  }

  save(value: Company): Observable<void> {
    return new Observable<void>();
  }

  update(value: Company): Observable<void> {
    return new Observable<void>();
  }
}


export const companyService = new CompanyService(companyStore);
