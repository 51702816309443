import React from 'react';
import './PossibilityLabels.scss';

import {IonGrid, IonRow, IonLabel, IonCol} from '@ionic/react';

export const PossibilityLabels: React.FunctionComponent = () => {
  return (
    <IonGrid className="possibility-wrapper">
      <IonRow className="percentages">
        <IonCol id="internal-number">
          <IonLabel>{'< '}10%</IonLabel>
        </IonCol>
        <IonCol className="ten-fory" >
          <IonLabel >10 - 40%</IonLabel>
        </IonCol>
        <IonCol className="fory-eig">
          <IonLabel>40 - 80%</IonLabel>
        </IonCol>
        <IonCol id="internal-number">
          <IonLabel>80 - 100%</IonLabel>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="auto" className="year-span-label">
          <IonLabel>Dentro de 3 año(s)</IonLabel>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
