import React from 'react';
import {IonButton} from '@ionic/react';
import {whatever} from '../../../constants';
import './UtilsButton.css';

interface Save {
  size?: 'small' | 'default' | 'large';
  function?: whatever;
  name: string;
}

export const Save = (props: Save) => (
  <div className="ion-text-left" style={{marginLeft: '5px'}}>
    <IonButton className={'ionic-button'} size={props.size ? props.size : 'default'} type="submit">
      {props.name}
    </IonButton>
  </div>
);
