/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, {AxiosResponse} from 'axios';
import {AUTH_URL, REG_EXPR, RISK_URL, whatever} from '../constants';
import {removeToken, setToken} from '../helpers/service';
import {Plugins} from '@capacitor/core';
import {clearListStorage, getStorage, setStorage} from '../helpers/localstorageUtil';


const {Network} = Plugins;

// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
const handler = Network.addListener('networkStatusChange', (status) => {});

const conf = {
  headers: {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
  },
};

const renewToken = async (): Promise<whatever> => {
  let response = {status: 0, data: {}};
  try {
    axios.defaults.baseURL = AUTH_URL;
    axios.defaults.headers = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('rToken'),
      },
    };
    const res = await axios.get('/renew', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('rToken'),
      },
    });
    response = {data: res.data, status: res.status};
  } catch (error) {
    response = {data: error.message, status: error.status};
  }

  return response;
};

const axiosI = axios.create({baseURL: RISK_URL, headers: conf.headers.headers});

let response: {
  status: number;
  data: whatever;
  message?: string;
  headers?: whatever;
};
axiosI.interceptors.request.use(async (response: whatever) => {
  return response;
});

axiosI.interceptors.response.use(async (resp: whatever) => {
  const status = await Network.getStatus();
  if (resp.data === 'Token expired' && !window.location.href.includes('tab15')) {
    setStorage('goBackUrl', window.location.href);
    setStorage('statusToken', 'Token expired');
    // window.location.replace('/#/tab15');
  }
  return resp;
});

const handleTokenExpired = (data: whatever): void => {
  if (
    data &&
      data.data &&
      typeof data.data === 'string' &&
      data.data.includes('401')) {
    setStorage('goBackUrl', window.location.href);
    setStorage('statusToken', 'Token expired');
    renewToken().then((value) => {
      setToken(value.data);
      if (window.location.href.includes('tab1')) window.location.reload();
      window.location.replace('/#/tab1');
    });
  }

  if ( getStorage('token') === 'undefined') {
    localStorage.clear();
    window.location.reload();
  }
};

export const get = async (url: string, baseURL?: string, headers?: whatever): Promise<whatever> => {
  try {
    if (baseURL) axiosI.defaults.baseURL = baseURL;
    else axiosI.defaults.baseURL = RISK_URL;
    if (headers) axiosI.defaults.headers = headers;
    const res: whatever = await axiosI.get(url);
    response = {data: res.data, status: res.status};
  } catch (error) {
    response = {data: error.message, message: 'error', status: error.status};
    handleTokenExpired(response);
  }
  return response;
};

export const post = async <T>(url: string, data?: T, baseURL?: string): Promise<whatever> => {
  try {
    if (baseURL) axiosI.defaults.baseURL = baseURL;
    else axiosI.defaults.baseURL = RISK_URL;
    const res: AxiosResponse = await axiosI.post(url, data);
    response = {data: res.data, status: res.status, headers: res.headers['Location']};
    handleTokenExpired(res);
  } catch (error) {
    response = {data: error.message, status: error.status};
  }
  return response;
};

export const put = async <T>(url: string, data?: T, baseURL?: string): Promise<whatever> => {
  try {
    if (baseURL) axiosI.defaults.baseURL = baseURL;
    else axiosI.defaults.baseURL = RISK_URL;
    const res: AxiosResponse = await axiosI.put(url, data);
    handleTokenExpired(res);
    response = {data: res.data, status: res.status};
  } catch (e) {
    response = {data: e.message, status: e.status};
  }
  return response;
};

export const checkExpiredToken = (data: whatever): void => {
  try {
    if (REG_EXPR.test(data)) {
      // removeToken();
      // window.location.href = window.location.origin;
    }
  } catch (error) {}
};

export const deletehttp = async (url: string, baseURL?: string): Promise<whatever> => {
  try {
    if (baseURL) axiosI.defaults.baseURL = baseURL;
    else axiosI.defaults.baseURL = RISK_URL;
    const res: AxiosResponse = await axiosI.delete(url);
    response = {data: res.data, status: res.status};
  } catch (error) {
    response = {data: error.message, status: error.status};
  }
  return response;
};

const generateUrlKey = (url: string): string => {
  let retAux = '';
  for (let i = url.length; i > 0; i--) {
    if (url.charAt(i) !== '/') retAux = url.charAt(i) + retAux;
    if (url.charAt(i) === '/') break;
  }
  return retAux;
};

export const checkNetwork = async (): Promise<whatever> => await Network.getStatus();
