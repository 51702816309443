import {checkExpiredToken, checkNetwork, deletehttp, get, post, put} from './httpAxios';
import {CONTROL_URL, CONTROL_URL_TYPES, POST, RISK_URL_RISKS, whatever} from '../constants';
import {setStorage} from '../helpers/localstorageUtil';
import {Control} from '../types/control';
import {getObject} from '../helpers/storageController';
import {Epp} from '../types/epp';
import {controlStore, ControlStore} from '../akita/stores/control.store';
import {from} from 'rxjs';
import {tap, switchMap, catchError} from 'rxjs/operators';
import {ID} from '@datorama/akita';


export class ControlService {
  constructor(private controlStore: ControlStore) {
  }

  public getControl(): whatever {
    return from(checkNetwork()).pipe(switchMap((check )=> {
      if (!check.connected) {
        return from(getObject('risks')).pipe(tap((riskControl: whatever)=> {
          this.controlStore.set(riskControl.data);
        }));
      } else {
        return from(get(CONTROL_URL)).pipe(tap((riskControl)=> {
          this.controlStore.add(riskControl.data);
        }));
      }
    },
    ));
  }

  public getControlById(id: ID): whatever {
    return from(get(`${CONTROL_URL}/${id}`)).pipe(tap((x) => {
      this.controlStore.set([x.data]);
    }));
  };

  public getControlTypes(storage?: boolean): whatever {
    return from(get(CONTROL_URL_TYPES)).pipe(tap((x) => {
      setStorage(CONTROL_URL_TYPES, storage && x.status ? x.data : []);
    }));
  }

  public getControlRisks(id: ID): whatever {
    return from(get(`${CONTROL_URL}/${id}/${RISK_URL_RISKS}`)).pipe(
        tap((x) => {
          this.controlStore.update(id, (control)=> ({...control, risks: x.data}));
        }),
        catchError((err) => err),
    );
  };

  public updateControl(data: Control): whatever {
    return from( put(`${CONTROL_URL}/${data.id}`, data)).pipe(tap((x)=>{
      this.controlStore.set([x.data]);
      this.controlStore.setLoading(false);
    }));
  }

  public setLoadingState(flag: boolean): void {
    this.controlStore.setLoading(flag);
  }

  public saveControl(data: Control): whatever {
    return from( post(CONTROL_URL, data)).pipe(
        tap((x)=>{
          setStorage(`${POST}${CONTROL_URL}`, data);
          checkExpiredToken(x.data);
          localStorage.removeItem(`${POST}${CONTROL_URL}`);
          this.controlStore.add(x.data.data);
        }));
  };

  public deleteControl(controlId: ID): whatever {
    return from(deletehttp(`${CONTROL_URL}/${controlId}`)).pipe(tap(() => {
      this.controlStore.remove(controlId);
    }));
  }

  public saveEpp(controlId: Control, data: Epp): whatever {
    return from( post(`${CONTROL_URL}/${controlId.id}/epp`, data)).pipe(
        tap((x)=>{
          setStorage(`${POST}${CONTROL_URL}`, data);
          checkExpiredToken(x.data);
          localStorage.removeItem(`${POST}${CONTROL_URL}`);
        }));
  };

  public setActive(id: ID | null): void {
    this.controlStore.setActive(id);
  }
}

export const controlService = new ControlService(controlStore);
