import {employeeQuery, EmployeeQuery} from '../../entity/query/employee.query';
import {employeeService, EmployeeService} from '../../entity/service/employee.service';
import {Observable, Subscription} from 'rxjs';
import {UsersType} from '../../entity/model/users.type';
import {ID} from '@datorama/akita';

export class EmployeeFacade {
  public readonly emmployees$ = this.employeeQuery.employees$;
  public readonly active$ = this.employeeQuery.active$ as Observable<UsersType>;
  public readonly loading$ = this.employeeQuery.loading$;
  private readonly subscriptions = new Subscription();

  constructor(private employeeQuery: EmployeeQuery, private employeeService: EmployeeService) {}

  getEmployees(): void {
    this.subscriptions.add(this.employeeService.getEmployees().subscribe());
  }

  updateEmployee(employeeId: ID, employee: UsersType): void {
    this.subscriptions.add(this.employeeService.updateEmployee(employeeId, employee).subscribe());
  }

  createEmployee(employee: UsersType, invitation = false): void {
    this.subscriptions.add(this.employeeService.createEmployee(employee, invitation).subscribe());
  }
}

export const employeeFacade = new EmployeeFacade(employeeQuery, employeeService);
