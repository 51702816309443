import {useObservable} from '@mindspace-io/utils';
import {RiskHookType} from './types/risk.hook.type';
import {ID} from '@datorama/akita';
import {whatever} from '../../constants';
import {Media as RiskPhotos} from '../../types/media';
import {riskFecade} from '../facade/risk.facade';
import {Risk} from '../../types/risk';

export function useRisk(): [RiskHookType] {
  const [risks] = useObservable<Risk[]>(riskFecade.risk$, []);
  const [activeRisk] = useObservable<Risk>(riskFecade.activeRisk$);

  const getRisks = (id?: ID, limit?: string): void => riskFecade.getRisks(id, limit);
  const getRiskCategory = (storage?: boolean): void => riskFecade.getRiskCategory(storage);
  const getRiskInherentImpact = (storage?: boolean): void => riskFecade.getRiskInherentImpact(storage);
  const getRiskPossibleEffect = (storage?: boolean ): void => riskFecade.getRiskPossibleEffect(storage);
  const getGeneral = (API: string, storage?: boolean): void => riskFecade.getGeneral(API, storage);
  const getRiskAppetites = (storage?: boolean): void => riskFecade.getRiskAppetites(storage);
  const saveRiskAppetites = (data: whatever): void => riskFecade.saveRiskAppetites(data);
  const postGeneral = (API: string, data: whatever): void => riskFecade.postGeneral(API, data);
  const updateRisk = (data: whatever, id: ID): void => riskFecade.updateRisk(data, id);
  const saveRisk = (data: whatever): void => riskFecade.saveRisk(data);
  const addControlToRisk = (riskId: ID, controlId: ID): void => riskFecade.addControlToRisk(riskId, controlId);
  const removeRisk = (riskId: ID): void => riskFecade.removeRisk(riskId);
  const addPhotos = (riskId: ID, data: RiskPhotos[]): void => riskFecade.addPhotos(riskId, data);
  const handleGetRiskList = (): void => riskFecade.handleGetRiskList();
  const killSubscription = (): void => riskFecade.killSubscription();
  const setAcvtiveRisk = (riskId: ID): void => riskFecade.setActive(riskId);
  const removeActive = (): void => riskFecade.removeActive();
  const updateActive= (newState: Risk): void => riskFecade.updateActive(newState);
  const removeControlFromRisk = (riskId: ID, controlId: ID): void => riskFecade.removeControlToRisk(riskId, controlId);


  return [
    {risks,
      activeRisk,
      getRisks,
      getRiskCategory,
      getRiskInherentImpact,
      getRiskPossibleEffect,
      getGeneral,
      getRiskAppetites,
      saveRiskAppetites,
      postGeneral,
      updateRisk,
      saveRisk,
      addControlToRisk,
      addPhotos,
      handleGetRiskList,
      killSubscription,
      removeRisk,
      setAcvtiveRisk,
      removeActive,
      updateActive,
      removeControlFromRisk,
    },
  ];
}
