import {QueryEntity} from '@datorama/akita';
import {EmployeeState} from '../model/users.type';
import {employeeStore, EmployeeStore} from '../store/employee.store';

export class EmployeeQuery extends QueryEntity<EmployeeState> {
    public employees$ = this.selectAll();
    public active$ = this.selectActive();
    public loading$ = this.selectLoading();


    constructor(protected employeeStore: EmployeeStore) {
      super(employeeStore);
    }
}
export const employeeQuery = new EmployeeQuery(employeeStore);
