import React from 'react';
import {IonText, IonButton, IonIcon} from '@ionic/react';
import {refresh} from 'ionicons/icons';
import Countdown from 'react-countdown';
import './ErrrorPage.scss';
interface Props {
  emojiDec?: string;
  message?: string;
}


const Completionist = () => <div>
  <IonText color="medium" >
    <h5 className={'textInfo'}>
      La carga de la información está tomando demasiado tiempo, tal vez sea un error de conexión.
      Si en 0 seg no se recarga, de click en el botón.</h5>
  </IonText>
  <IonButton color="primary" onClick={ () => window.location.replace('/') } >
    <IonIcon icon={refresh} ></IonIcon>
  </IonButton>
</div>;

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const renderer = ({seconds, completed}) => {
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <IonText color="medium" >
        <h5 className={'textInfo'}>
          La carga de la información está tomando demasiado tiempo, tal vez sea un error de conexión.
          Si en {seconds} seg no se recarga, de click en el botón.</h5>
      </IonText>
    );
  }
};

class ErrorPage404 extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {hasError: false, time: {}, seconds: 5};
  }

static defaultProps = {
  emojiDec: '128373',
}

static getDerivedStateFromError(): whatever {
  return {hasError: true};
}


render(): whatever {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore

  if (this.state.hasError) {
    return <div
      style={{
        textAlign: 'center',
        margin: '0',
      }}
      className="animated bounceInDown delay-1s fast"
    >
      <div
        style={{
          fontSize: '75px',
          marginTop: '20px',
        }}
        dangerouslySetInnerHTML={{__html: '&#' + this.props.emojiDec + ';'}}
      />
      <br/>
      <Countdown date={Date.now() + 5000} renderer={renderer} />
    </div>;
  }

  return this.props.children;
}
}


export default ErrorPage404;

