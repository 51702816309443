/* eslint-disable @typescript-eslint/no-useless-constructor */
import {EntityStore, StoreConfig} from '@datorama/akita';
import {CompanyState} from '../model/company.model';

@StoreConfig({name: 'company', idKey: 'id'})
export class CompanyStore extends EntityStore<CompanyState> {
  constructor() {
    super();
  }
}

export const companyStore = new CompanyStore();
