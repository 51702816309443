import React from 'react';
import {IonRow, IonCol, IonLabel, IonGrid} from '@ionic/react';
import './ImpactDescription.scss';
import {Impact} from '../../../../../types/impact';

interface Prop {
  impacts: Impact[];
}

export const ImpactDescription: React.FunctionComponent<Prop> = (props) => {
  return (
    <IonGrid className="impact-description-wrapper">
      {/* eslint-disable-next-line react/prop-types */}
      {props.impacts.map((impact: Impact, index: number) => {
        return (
          <IonRow key={index} style={{marginTop: '20px'}}>
            <IonCol size-md="3">
              {/* eslint-disable-next-line max-len */}
              <IonLabel className={impact.impactOrder === 1 ? 'order-1' : 'order-style'}>{impact.impactOrder} </IonLabel><IonLabel className="description-style">{impact.description}</IonLabel>
            </IonCol>
          </IonRow>
        );
      })}
    </IonGrid>
  );
};
